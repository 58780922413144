import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ComboBoxModule } from "@progress/kendo-angular-dropdowns";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { SchedulerModule } from "@progress/kendo-angular-scheduler";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { OrderDialogComponent } from "./order-dialog/order-dialog.component";
import { DialogModule, DialogsModule } from "@progress/kendo-angular-dialog";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { HttpClientModule } from "@angular/common/http";
import { ScheduleComponent } from "./schedule/schedule.component";
import { DayScheduleComponent } from "./day-schedule/day-schedule.component";
import { SpeakersComponent } from "./speakers/speakers.component";
import { NewsletterFormComponent } from "./newsletter-form/newsletter-form.component";
import { MainContentComponent } from "./main-content/main-content.component";
import { CodeOfConductComponent } from "./code-of-conduct/code-of-conduct.component";
import { ImprintComponent } from "./imprint/imprint.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { TermsOfUseComponent } from "./terms-of-use/terms-of-use.component";
import { SessionsComponent } from "./sessions/sessions.component";
import { SessionsService } from "./sessions/sessions.service";
import { SponsorsComponent } from "./sponsors/sponsors.component";
import { SupportersComponent } from "./supporters/supporters.component";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { RegisterModule } from "./register/register.module";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { SignInModalComponent } from "./sign-in-modal/sign-in-modal.component";
import { SharedModule } from "./shared/shared.module";
import { TagCloudComponent } from './tag-cloud/tag-cloud.component';
import { TagCloudModule } from "angular-tag-cloud-module";
import { ScheduleService } from "./schedule/schedule.service";
import { DayViewComponent } from './schedule/day-view/day-view.component';
import { ForgotPasswordModalComponent } from './forgot-password-modal/forgot-password-modal.component';
import { ResizeSensorModule } from '@progress/kendo-angular-common';
import { NerdQuizComponent } from './nerd-quiz-section/nerd-quiz.component';
@NgModule({
  declarations: [
    AppComponent,
    OrderDialogComponent,
    ScheduleComponent,
    DayScheduleComponent,
    SpeakersComponent,
    NewsletterFormComponent,
    MainContentComponent,
    CodeOfConductComponent,
    ImprintComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    SessionsComponent,
    SponsorsComponent,
    SupportersComponent,
    SignInModalComponent,
    TagCloudComponent,
    DayViewComponent,
    ForgotPasswordModalComponent,
    NerdQuizComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    DialogModule,
    ComboBoxModule,
    InputsModule,
    ReactiveFormsModule,
    ButtonsModule,
    HttpClientModule,
    SchedulerModule,
    DateInputsModule,
    LayoutModule,
    DialogsModule,
    RegisterModule,
    IndicatorsModule,
    DialogsModule,
    TagCloudModule,
    ResizeSensorModule,
    SharedModule.forRoot()
  ],
  entryComponents: [OrderDialogComponent],
  providers: [
    SessionsService,
    ScheduleService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
