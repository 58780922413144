import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
    name: 'SessionDescription'
})
export class SessionDescriptionPipe implements PipeTransform {

    constructor(private domSanitizer: DomSanitizer) {}

    transform(description: string): SafeHtml {
        let html = `<p class="session-description">`
        const listRegex = /^((\-\ \.\ )|(\n\.)|(\n\ \-)|(\n\-)|(\ \.)|(\ \-)|(\n\-\ )|(\-\ )|(\.\ )).*/gm;
        const listSeparatorRegex =  /^((\-\ \.\ )|(\n\.)|(\n\ \-)|(\n\-)|(\ \.)|(\ \-)|(\n\-\ )|(\-\ )|(\.\ ))/gm;
        const listItems = description.match(listRegex) || [];
        for (let listItem of listItems) {
            let replacement = listItem.replace(listSeparatorRegex,  '<li class="description-list-item">') + '</li>';
            if (replacement.startsWith('<li class="description-list-item"> . ')) {
                replacement = replacement.replace(' . ', '');
            }
            description = description.replace(listItem, replacement);
        }
        description = description.replace(new RegExp('\r\n', 'g'), '<br>');
        html += description;
        html += `</p>`;
        return this.domSanitizer.bypassSecurityTrustHtml(html);
    }
}