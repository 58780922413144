<!-- <div class="wrap">
<div class="row">
    <div class="col-10 col-lg-4">
      <a target="_blank" href="https://www.consultingwerk.com/">
        <img
          alt="Consultingwerk"
          src="../assets/images/sponsors/cwlogo.png"
        />
      </a>
    </div>
    <div class="col-10 col-lg-4">
      <a target="_blank" href="https://riverside-software.fr/">
        <img
          alt="Riverside Software"
          src="../assets/images/sponsors/riverside_horiz2.png"
        />
      </a>
    </div>
    <div class="col-10 col-lg-4">
      <a target="_blank" href="https://wss.com/">
        <img
          alt="White Star Software"
          src="../assets/images/sponsors/wss.png"
        />
      </a>
    </div>
  </div>
  </div> -->

  <div class="d-flex w-100 text-center sponsors-wrap" id="wrap">
    <a target="_blank" href="https://www.consultingwerk.com/">
        <img
          alt="Consultingwerk"
          src="../assets/images/sponsors/consultingwerk.png"
        />
      </a>
      <a target="_blank" href="https://riverside-software.fr/" class="mid">
        <img
          alt="Riverside Software"
          src="../assets/images/sponsors/riverside_horiz2.png"
          style="min-width: 75%;"
        />
      </a>
      <a target="_blank" href="https://wss.com/" id="wss" class="mid">
        <img
          alt="White Star Software"
          src="../assets/images/sponsors/wss.png"
        />
      </a>
  </div>
  <div class="d-flex w-100 text-center sponsors-wrap">
    <a target="_blank" href="https://www.progress.com/" id="progress">
        <img
          alt="Progress"
          src="../assets/images/sponsors/progress.png"
        />
      </a>
      <a target="_blank" href="https://www.app-solutions.com/appsolute-knowledge" id="appSolute">
        <img
          alt="appSolute"
          src="../assets/images/sponsors/appSolute_knowledge.png"
        />
      </a>
  </div>