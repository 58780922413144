import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, TemplateRef } from "@angular/core";
import { ActionsLayout, DialogService } from "@progress/kendo-angular-dialog";

@Injectable()
export class FeedbackService {
  constructor(private dialogService: DialogService) {}

  handleError(err: string | Error | HttpErrorResponse) {
    if (err instanceof HttpErrorResponse) {
      err = err.error.message;
    }
    if (typeof err !== "string") {
      err = err.message;
    }
    return this.dialogService.open({
      title: "Error",
      content: err,
      actions: [{ text: "OK", primary: true }],
    });
  }

  showSuccess(message: string | TemplateRef<any>, title = "Success") {
    return this.dialogService.open({
      title,
      content: message,
      actions: [
        {
          text: "OK",
          primary: true,
        },
      ],
    });
  }
}
