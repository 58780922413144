    <div class="container">
        <form id="form-wrap" class="k-form d-flex flex-column p-0 pt-4 col-12" #participantForm="ngForm">
            <div class="row mx-0 px-3">
                <div class="col-12">
                    <fieldset class="pt-2">
                        <legend>Before you submit</legend>
                        
                    <label class="k-form-field">
                        <input type="checkbox" class="k-checkbox" required id="privacy" name="privacy" #privacy [(ngModel)]="agreeToPrivacyStatement">
                        <label class="k-checkbox-label" for="privacy">I have read and agree to <a href="/privacy" target="_blank">the privacy policy</a></label>
                    </label>
                    <label class="k-form-field">
                        <input type="checkbox" class="k-checkbox" required id="useName" name="useName" #useName [(ngModel)]="agreeToUseName">
                        <label class="k-checkbox-label" for="useName">I agree that my name may be published to social media if I am the winner of this competition</label>
                    </label>
                    </fieldset>
                </div>
            </div>
            <input
                class="k-button k-primary"
                [disabled]="!agreeToPrivacyStatement || !agreeToUseName"
                    (click)="submit()"
                    type="submit"
                    value="SUBMIT"/>
    </form>
</div>
