import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PassedDeadlineComponent } from "./passed-deadline/passed-deadline.component";
import { QuestionsComponent } from "./questions/questions/questions.component";
import { DeadlineGuard } from "./shared/deadline.guard";
import { QuestionsAnsweredGuard } from "./shared/questions-answered.guard";
import { SplashComponent } from "./splash/splash.component";
import { SubmitComponent } from "./submit/submit.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/quiz/questions',
    pathMatch: 'full'
  },
  {
    path: 'splash',
    component: SplashComponent,
    // canActivate: [
    //   AuthGuard
    // ]
  },
  {
    path: 'end',
    canActivate: [
      // DeadlineGuard,
      // AuthGuard
    ],
    component: PassedDeadlineComponent
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  //   canActivate: [
  //     // AuthGuard
  //   ]
  // },
  {
    path: 'questions',
    component: QuestionsComponent,
    canActivate: [
      DeadlineGuard,
      // AuthGuard
    ]
  },
  {
    path: 'submit',
    component: SubmitComponent,
    canActivate: [
      DeadlineGuard,
      // AuthGuard,
      QuestionsAnsweredGuard
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuizRoutingModule {}
