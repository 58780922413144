<kendo-combobox
  [data]="timezones"
  [valuePrimitive]="true"
  valueField="id"
  textField="label"
  (ngModelChange)="onChange($event)"
  [disabled]="disabled"
  (blur)="onBlur()"
  [ngModel]="value"
  [kendoDropDownFilter]="{ field: 'label', operator: 'contains' }"
  [filterable]="true"
>
</kendo-combobox>
