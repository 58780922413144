<!-- <div class="wrap">
<div class="row">
    <div class="col-10 col-lg-4">
      <a target="_blank" href="https://www.consultingwerk.com/">
        <img
          alt="Consultingwerk"
          src="../assets/images/sponsors/cwlogo.png"
        />
      </a>
    </div>
    <div class="col-10 col-lg-4">
      <a target="_blank" href="https://riverside-software.fr/">
        <img
          alt="Riverside Software"
          src="../assets/images/sponsors/riverside_horiz2.png"
        />
      </a>
    </div>
    <div class="col-10 col-lg-4">
      <a target="_blank" href="https://wss.com/">
        <img
          alt="White Star Software"
          src="../assets/images/sponsors/wss.png"
        />
      </a>
    </div>
  </div>
  </div> -->

  <div class="d-flex text-center wrap-supporters">
    
    <a target="_blank" href="https://inmydata.com/">
      <img
        alt="inmydata"
        src="../assets/images/supporters/inmydata.png"
      />
    </a>
    <a target="_blank" href="https://www.proginov.com/en/">
      <img
        alt="Proginov"
        src="../assets/images/supporters/proginov.png"
      />
    </a>
    <a target="_blank" href="https://www.acorn.ro">
      <img
        id="acorn-it"
        alt="Acorn IT"
        src="../assets/images/supporters/acorn.png"
      />
    </a>
    <a target="_blank" href="https://www.ba.lt/en">
      <img
        alt="Baltic Amadeus"
        src="../assets/images/supporters/baltic_amadeus.png"
      />
    </a>  
    <a target="_blank" href="https://www.iap.de/" id="iap-link">
      <img
        id="iap"
        alt="IAP"
        src="../assets/images/supporters/IAP.png"
      />
    </a> 
    <a target="_blank" href="https://wayfare.ro/">
      <img
        id="wayfare"
        alt="wayfare"
        src="../assets/images/supporters/wayfare.png"
      />
    </a>  
  </div>
  <div class="d-flex text-center wrap-supporters">
    <a target="_blank" href="https://build.one/de/" id="buildone-link">
      <img
        alt="build.one"
        src="../assets/images/supporters/buildone_navy.png"
      />
    </a>
    <a target="_blank" href="https://www.flusso.nl">
      <img
        alt="Flusso"
        src="../assets/images/supporters/flusso.png"
      />
    </a>
    <a target="_blank" href="https://www.ginkgo-analytics.com/">
      <img
        alt="Ginkgo Analytics"
        src="../assets/images/supporters/ginkgo-analytics.png"
      />
    </a>
    <a target="_blank" href="https://www.wits.it/" id="wits-link">
      <img
        id="wits"
        alt="WITS"
        src="../assets/images/supporters/wits.png"
      />
    </a>
    <a target="_blank" href="http://www.nodeable.io">
      <img
        alt="nodeable"
        src="../assets/images/supporters/nodeable.png"
      />
    </a>
    <a target="_blank" href="https://www.cyberscience.com/" id="cyberscience-link">
      <img
        alt="cyberscience"
        src="../assets/images/supporters/cyberscience.jpg"
      />
    </a>
  </div>