<form class="k-form mb-2" #signin id="signin">
    <div class="kendo-form-field">
        <label for="username">Username</label>
        <input class="k-textbox" type="text" required [(ngModel)]="username" id="username" name="username">
    </div>
    <div class="kendo-form-field">
        <label for="password">Password</label>
        <input type="password" class="k-textbox" required [(ngModel)]="password" id="password" name="password">
    </div>
    <div class="k-form-field alert alert-info" [ngClass]="{ 'mb-0': !errorMessage }">
        <span class="k-icon k-i-info mr-2"></span>Your username is not your email address. <br> If you no longer remember your username, please<br> use the "I have forgotten my password" link below.
    </div>
    <div *ngIf="errorMessage" class="k-form-field alert alert-danger mb-0">
        {{errorMessage}}
    </div>
</form>
<a (click)="openForgotPasswordModal()" id="reset-password-link" >I have forgotten my password</a>
<kendo-dialog-actions>
    <button
        [disabled]="!username || !password || loading"
        id="btn-submit"
        class="k-button k-primary"
        (click)="submit()"
        type="submit"
        form="signin"
        >
        <span [hidden]="loading">Submit</span>
        <kendo-loader
            *ngIf="loading"
            [type]="'infinite-spinner'"
            themeColor="info"
            size="small"
        >
        </kendo-loader>
    </button>
    <button class="k-button" type="button" (click)="cancel()">Cancel</button>
</kendo-dialog-actions>