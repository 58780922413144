<div class="container-fluid" style="max-width: 1200px">
    <div class="row">
        <div class="col-12 text-center">
            <img src="./assets/quizlogo.png" class="mx-auto" style="width: auto; max-width: 100%;">
        </div>
    </div>
    <div class="row">
        <div class="col-12 mt-3">
            <p>
                Welcome to the POET Summit Trivia Quiz Competition! Clicking "Begin" will start the quiz. Please note that there is a time limit of {{
                    config?.Client.QuestionTimeout}}
                  seconds per question and you will only be able to take the quiz once. Good luck!
            </p>
        </div>
    </div>
</div>