import { Component, OnInit, Input } from '@angular/core';
import { SchedulerEvent, EventStyleArgs } from '@progress/kendo-angular-scheduler';
import '@progress/kendo-date-math/tz/Australia/Sydney';

@Component({
  selector: 'app-day-schedule',
  templateUrl: './day-schedule.component.html',
  styleUrls: ['./day-schedule.component.css']
})
export class DayScheduleComponent implements OnInit {

  @Input('events') events: SchedulerEvent[];
  @Input('selectedDate') selectedDate: Date;
  @Input('hideEventTimes') hideEventTimes: boolean;
  
  constructor() { }

  ngOnInit() {
    
  }

  public getEventClass = (args: EventStyleArgs) => {
    return args.event.dataItem.type;
  }

}
