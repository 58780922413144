<div class="d-flex flex-column">
  <nav
    class="navbar navbar-lg navbar-expand-xl navbar-light bg-light fixed-top"
    id="navbar"
  >
    <div class="container">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="d-flex flex-column w-100">
          <div class="d-flex flex-row w-100">
            <div class="d-flex flex-row mr-auto">
              <a class="navbar-brand d-none d-xl-block" id="consultingwerkLogo" target="_blank" href="https://www.consultingwerk.com/">
                <img src="../assets/images/sponsors/cwlogo_small.png" height="35" alt="consultingwerk">
              </a>
              <a class="navbar-brand d-none d-xl-block" id="riversideLogo" target="_blank" href="https://riverside-software.fr/">
                <img src="../assets/images/sponsors/riverside_horiz2.png" height="35" alt="riverside_software">
              </a>
              <a class="navbar-brand d-none d-xl-block" id="wssLogo" target="_blank" href="https://wss.com/">
                <img src="../assets/images/sponsors/wss.png" height="51" alt="white_star_software">
              </a>
            </div>

            
            <div id="poets-title" class="ml-auto">
              <p style="font-size: 20px;" class="d-none d-xl-block py-0 my-0">Progress OpenEdge Technology Summit</p>
              <p style="font-size: 13px; text-align: right;" class="d-none d-xl-block py-0 my-0 ml-auto">November 9th and 10th 2021</p>
            </div>
          </div>

          <ul class="navbar-nav w-100 ml-auto">
            <li class="nav-item" id="menu-event" >
              <a [ngClass]="{'nav-link': true, 'active': isMenuItemSelected('event'), 'inactive-item': !isMenuItemSelected('event')}" href="javascript:void(0)" (click)="selectMenuItem('event')"> Event </a>
            </li>
            <li class="nav-item" id="menu-sponsors" >
              <a [ngClass]="{'nav-link': true, 'active': isMenuItemSelected('sponsors'), 'inactive-item': !isMenuItemSelected('sponsors')}" href="javascript:void(0)" (click)="selectMenuItem('sponsors')"> Sponsors </a>
            </li>
            <li class="nav-item" id="menu-nerd-quiz">
              <a [ngClass]="{'nav-link': true, 'active': isMenuItemSelected('nerd-quiz'), 'inactive-item': !isMenuItemSelected('nerd-quiz')}" href="javascript:void(0)" (click)="selectMenuItem('nerd-quiz')"> Nerd Quiz </a>
            </li>
            <li class="nav-item" id="menu-speakers" >
              <a [ngClass]="{'nav-link': true, 'active': isMenuItemSelected('speakers'), 'inactive-item': !isMenuItemSelected('speakers')}" href="javascript:void(0)" (click)="selectMenuItem('speakers')"> Speakers </a>
            </li>
            <li class="nav-item" id="menu-program" >
              <a [ngClass]="{'nav-link': true, 'active': isMenuItemSelected('program'), 'inactive-item': !isMenuItemSelected('program')}" href="javascript:void(0)" (click)="selectMenuItem('program')"> Program </a>
            </li>
            <li class="nav-item" id="menu-schedule" >
              <a class="nav-link" [routerLink]="'/schedule'"> Schedule </a>
            </li>
            <li class="nav-item" id="menu-sessions" >
              <a class="nav-link" [routerLink]="'/sessions'"> Sessions </a>
            </li>
            <!-- <li class="nav-item" id="menu-quiz" *ngIf="isQuizActive && authenticated">
              <a class="nav-link" [routerLink]="'/quiz'"> Quiz </a>
            </li> -->
            <li class="nav-item" id="menu-newsletter" >
              <a [ngClass]="{'nav-link': true, 'active': isMenuItemSelected('newsletter-form'), 'inactive-item': !isMenuItemSelected('newsletter-form')}" href="javascript:void(0)" (click)="selectMenuItem('newsletter-form')"> Newsletter </a>
            </li>
            <li class="nav-item" id="menu-registration" *ngIf="!authenticated">
              <a [ngClass]="{'nav-link': true, 'active': isMenuItemSelected('registration'), 'inactive-item': !isMenuItemSelected('registration')}" href="javascript:void(0)" (click)="selectMenuItem('registration')"> Registration </a>
            </li>
            <li class="nav-item" *ngIf="!sessionContext">
              <a class="nav-link" href="javascript:void()" (click)="signIn()">Sign In</a>
            </li>
            <li class="nav-item dropdown" *ngIf="sessionContext">
              <a class="nav-link dropdown-toggle d-none d-xl-block" id="icon-dropdown" data-toggle="dropdown" role="button" aria-disabled="false" aria-haspopup="true" href="javascript:void(0)">
                <div style="display: flex; flex-direction: column; justify-content: center; justify-items: center;">
                  <em class="fa fa-user d-none d-xl-block"></em>
                </div>
              </a>
              <a class="nav-link dropdown-toggle d-block d-xl-none" data-toggle="dropdown" role="button" aria-haspopup="tree" aria-disabled="false" href="javascript:void(0)">
                {{sessionContext.DelegateName}}
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" [routerLink]="'/profile'">Profile</a>
                <a class="dropdown-item" [routerLink]="'/my-sessions'">My Sessions</a>
                <a class="dropdown-item" role="button" (click)="signOut()">Sign out</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>

  <div class="flex-grow-1 content-container">
    <router-outlet></router-outlet>
  </div>

  <div class="bottom-footer" [hidden]="isQuiz">
    <div class="py-3">
      <div class="d-none d-lg-flex">
        <div class="flex-self-start" id="social-wrap-large">
          <a
            class="social-media-link"
            target="_blank"
            href="https://www.facebook.com/POETSummit"
          >
            <i class="fa fa-facebook" aria-hidden="true"></i>
          </a>
          <a
            class="social-media-link ml-2"
            target="_blank"
            href="https://twitter.com/PoetSummit">
              <i class="fa fa-twitter" aria-hidden="true"></i>
          </a>
        </div>
        <div style="flex: 1;"></div>

        <div class="flex-self-end">
          <a class="footer-link" [routerLink]="['/code-of-conduct']">Code of conduct</a>
          <span class="separator-bar"> | </span>
          <a class="footer-link" [routerLink]="['/imprint']">Imprint</a>
          <span class="separator-bar"> | </span>
          <a class="footer-link" [routerLink]="['/privacy']">Privacy</a>
          <span class="separator-bar"> | </span>
          <a class="footer-link" [routerLink]="['/terms-of-use']">Terms of use</a>
        </div>
      </div>

      <div class="d-flex d-lg-none py-2" style="flex-direction: column;">
        <a
          class="social-media-link"
          target="_blank"
          href="https://www.facebook.com/POETSummit"
        >
          <i class="fa fa-facebook" aria-hidden="true"></i>
          Facebook
        </a>
        <a
          class="social-media-link"
          target="_blank"
          href="https://twitter.com/PoetSummit">
            <i class="fa fa-twitter" aria-hidden="true"></i>
            Twitter
        </a>
        <a class="footer-link text-center" [routerLink]="['/code-of-conduct']">Code of conduct</a>
        <a class="footer-link text-center" [routerLink]="['/imprint']">Imprint</a>
        <a class="footer-link text-center" [routerLink]="['/privacy']">Privacy</a>
        <a class="footer-link text-center" [routerLink]="['/terms-of-use']">Terms of use</a>
      </div>
    </div>
  </div>

</div>

<div kendoDialogContainer></div>