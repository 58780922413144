import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService } from "@progress/kendo-angular-dialog";
import { Observable, ReplaySubject } from "rxjs";
import { switchMap, take, takeUntil } from "rxjs/operators";
import { ResetPasswordModalComponent } from "../reset-password/reset-password-modal/reset-password-modal.component";
import { SessionsComponent } from "../sessions/sessions.component";
import { FeedbackService } from "../shared/feedback.service";
import { UserService } from "../shared/user.service";

@Component({
  selector: "main-content",
  templateUrl: "./main-content.component.html",
  styleUrls: ["./main-content.component.scss"],
})
export class MainContentComponent implements OnInit, AfterViewInit, OnDestroy {
  private _isAuthenticated: boolean;
  formSource: string;
  formQueryString: string;

  @ViewChild(SessionsComponent)
  sessions: SessionsComponent;
  isAuthenticated: Observable<boolean>;
  readonly destroyed$: ReplaySubject<void> = new ReplaySubject(1);

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private dialogService: DialogService,
    private feedbackService: FeedbackService
  ) {}

  ngOnInit() {
    const params = new URLSearchParams(window.location.search);
    const confirm = params.get("confirm");
    if (!confirm) {
      this.formSource = "https://www.consultingwerk.com/poet-summit";
    } else {
      const info = confirm.split(":");
      this.formQueryString = `redirectUrl=${encodeURI(
        "https://www.consultingwerk.com/poet-summit/newsletter-confirmation"
      )}`;
      this.formSource = `https://www.consultingwerk.com/webapi/newslettersubscriber/${info[0]}/${info[1]}/confirm`;
    }
    this.isAuthenticated = this.userService
        .authenticated
        .pipe(
          takeUntil(this.destroyed$)
        );
    this.isAuthenticated.subscribe(isAuthenticated => {
      if (isAuthenticated && !this._isAuthenticated) {
        this.router.navigateByUrl('/main/program')
            .then(() => this.scrollIntoView('#program'))
            .catch(() => {});
      }
      this._isAuthenticated = isAuthenticated;
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.passwordResetToken) {
        const token = params.passwordResetToken;

        const dialogRef = this.dialogService
        .open({
          title: "Reset Password",
          content: ResetPasswordModalComponent,
          width: 240
        })

        dialogRef.result.subscribe((result: { password: string }) => {
          this.userService.resetPassword(result.password, token).subscribe(() => {
            this.feedbackService.showSuccess('Your password has been successfully reset.')
          }, (err: HttpErrorResponse) => {
            this.feedbackService.handleError(err);
          })
        });
      }
    })
  }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe((params) => {
      const hash = params.hash;

      if (!hash) {
        return;
      }

      this.scrollIntoView(`#${hash}`);
    });
  }

  scrollIntoView(selector: string) {
    const element = this.elementRef.nativeElement.querySelector(
      selector
    ) as HTMLElement;
    element.scrollIntoView();
    document.scrollingElement.scrollTop -= 70;
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
