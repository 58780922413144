import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ITimeZone, TimeZonesService } from "../shared/timezones/timezones.service";
import { RegistrationService } from "./registration.service";
import { FeedbackService } from "../shared/feedback.service";
import { ActivatedRoute } from "@angular/router";
import { filter, switchMap } from "rxjs/operators";
import { TooltipDirective } from "@progress/kendo-angular-tooltip";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  @ViewChild(TooltipDirective) invalidUsernameTooltip: TooltipDirective;
  @ViewChild('userName', { read: ElementRef }) userNameInput: ElementRef<HTMLInputElement>;

  loading = false;
  form = new FormGroup({
    DelegateName: new FormControl("", Validators.required),
    DelegateEmail: new FormControl("", [Validators.email, Validators.required]),
    DelegateCompany: new FormControl("", Validators.required),
    DelegateInterest: new FormControl(""),
    DelegateOpenEdgeReleases: new FormControl("", Validators.required),
    DelegateRemarks: new FormControl(""),
    DelegateFunction: new FormControl("", Validators.required),
    DelegateCountry: new FormControl("", Validators.required),
    DelegateTimeZone: new FormControl(
      "8c438b8b-b3c4-4de8-9702-b90e2cd067e1",
      Validators.required
    ),
    DelegateLoginName: new FormControl("", [Validators.required, (formControl: FormControl) => {
      const value = formControl.value;
      if (!value) {
        this.invalidUsernameTooltip?.hide();
        return null;
      }
      if (value.includes('@') || value.includes(' ')) {
        console.log(this.invalidUsernameTooltip)
        this.invalidUsernameTooltip?.show(this.userNameInput);
        return { invalidUsername: true };
      }
      this.invalidUsernameTooltip?.hide();
      return null;
    }]),
    DelegatePassword: new FormControl("", Validators.required),
    ConfirmPassword: new FormControl("", [
      Validators.required,
      (formControl: FormControl) => {
        if (!formControl.parent) {
          return null;
        }
        return formControl.value !==
          formControl.parent.value.DelegatePassword && formControl.touched
          ? { pwdMismatch: true }
          : null;
      },
    ]),
  });

  timezones: ITimeZone[];

  @ViewChild("regSuccessTemplate", { read: TemplateRef })
  registrationSuccessTemplate: TemplateRef<any>;

  constructor(
    private registrationService: RegistrationService,
    private timezoneService: TimeZonesService,
    private feedbackService: FeedbackService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.timezoneService
      .getTimeZones()
      .subscribe((timezones) => (this.timezones = timezones));
  }

  ngAfterViewInit() {
    this.route.queryParams
      .pipe(
        filter((params) => !!params.confirmAccount),
        switchMap((params) =>
          this.registrationService.confirmEmail(params.confirmAccount)
        )
      )
      .subscribe(
        () =>
          this.feedbackService.showSuccess(
            "Thank you. Your registration was successful."
          ),
        (err) => this.feedbackService.handleError(err)
      );
  }

  submit() {
    this.form.disable();
    this.loading = true;
    const value = { ...this.form.value };
    delete value.ConfirmPassword;
    const tz = this.timezones.find((t) => t.id === value.DelegateTimeZone);
    value.DelegateTimeZone = tz.offset;

    this.registrationService.registerUser(value).subscribe(
      () => {
        this.loading = false;
        this.form.enable();
        this.feedbackService.showSuccess(this.registrationSuccessTemplate);
      },
      (err) => {
        this.loading = false;
        this.form.enable();
        this.feedbackService.handleError(err);
      }
    );
  }
}
