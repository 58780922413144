declare var $;


export function setCollapsibleParagraph(paragraphId: string) {
    document.getElementById(`${paragraphId}-more`).style.display = "inline";
    document.getElementById(`${paragraphId}-less`).style.display = "none";

    $(`#${paragraphId}-more`).click(function () {
      $(`.${paragraphId}-collapsible`).collapse('show');
      document.getElementById(`${paragraphId}-more`).style.display = "none";
      document.getElementById(`${paragraphId}-less`).style.display = "inline";
    });
    $(`#${paragraphId}-less`).click(function () {
      $(`.${paragraphId}-collapsible`).collapse('hide');
      document.getElementById(`${paragraphId}-more`).style.display = "inline";
      document.getElementById(`${paragraphId}-less`).style.display = "none";
    });
  }