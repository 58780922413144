import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Session } from '../../../model/session.model';

@Component({
  selector: 'app-session-view',
  templateUrl: './session-view.component.html',
  styleUrls: ['./session-view.component.scss']
})
export class SessionViewComponent implements OnInit {

  @Input()
  session: Session;

  @Input()
  isAuthenticated: boolean;

  @Output()
  toggleFavorite: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
