
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class RefreshGuard implements CanActivate {


    constructor(private userService: UserService,
                private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        console.log('refresh guard')
        console.log(next.routeConfig.path);
        console.log(state.url)
        if (next.routeConfig.path === 'splash' && (!!state && state.url.indexOf('/splash') >= 0)) {
            // user is using the back button
            window.location.reload();
            return false;
        }
        return true;
    }
}
