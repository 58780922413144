import { Pipe, PipeTransform } from "@angular/core";
import { Session } from "../../model/session.model";

@Pipe({
  name: "SessionsFilter",
})
export class SessionsFilterPipe implements PipeTransform {
  transform(
    value: Session[],
    speaker: string,
    tag: string,
    track: string
  ) {
    if (!value || value.length === 0) {
      return [];
    }
    let filtered = [...value].filter(s => s.Track && s.Track !== '');

    if (speaker && speaker.length > 0) {
      filtered = filtered.filter((s) => {
        return !!s.Session_Speakers.find((sessionSpeaker) => sessionSpeaker.SpeakerID === speaker);
      });
    }
    if (tag && tag.length > 0) {
      filtered = filtered.filter((s) => {
        return s.Session_Tags.includes(tag);
      });
    }
    if (!track || track === "All") {
      return filtered;
    }
    filtered = filtered.filter((s) => s.Track === track);
    return filtered;
  }
}
