import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from './config.model';

@Injectable()
export class QuizConfigService {

  constructor(private http: HttpClient) { }

  getConfig(): Observable<Config> {
    return this.http.get<Config>('https://poet-summit.org/api/quiz/config');
  }
}
