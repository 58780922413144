<form class="k-form row px-0 mx-auto" [formGroup]="form" kendoTooltip filter="#userName" [tooltipTemplate]="invalidUserNameTemplate" showOn="none">
  <fieldset class="fieldset col-12 col-xl-6 pl-0 pr-xl-0">
    <div class="k-form-field">
      <label for="name">Name</label>
      <input
        class="k-textbox"
        type="text"
        formControlName="DelegateName"
        id="name"
      />
    </div>
    <div class="k-form-field">
      <label for="email">Contact Email</label>
      <input
        class="k-textbox"
        type="email"
        formControlName="DelegateEmail"
        id="email"
      />
    </div>
    <div class="k-form-field">
      <label for="company">Company</label>
      <input
        class="k-textbox"
        type="text"
        formControlName="DelegateCompany"
        id="company"
      />
    </div>
    <div class="k-form-field">
      <label for="position">Function</label>
      <input
        class="k-textbox"
        type="text"
        formControlName="DelegateFunction"
        id="position"
      />
    </div>
    <div class="k-form-field">
      <label for="country">Country</label>
      <input
        class="k-textbox"
        type="text"
        formControlName="DelegateCountry"
        id="country"
      />
    </div>
  </fieldset>
  <fieldset class="fieldset col-12 col-xl-6 pl-0 pl-xl-3 pr-xl-0">
    <div class="k-form-field">
      <label for="userName">Username</label>
      <input
        type="text"
        id="userName"
        #userName
        formControlName="DelegateLoginName"
        class="k-textbox"
      />
    </div>
    <div class="k-form-field">
      <label for="userName">Password</label>
      <input
        type="password"
        formControlName="DelegatePassword"
        class="k-textbox"
      />
    </div>
    <div class="k-form-field">
      <label for="userName">Confirm Password</label>
      <input
        type="password"
        formControlName="ConfirmPassword"
        class="k-textbox"
      />
    </div>
    <div class="k-form-field">
      <label for="timezone">Time Zone</label>
      <app-timezones-dropdown formControlName="DelegateTimeZone"></app-timezones-dropdown>
    </div>
    <div class="k-form-field">
        <label for="oereleases">OpenEdge Releases in Use</label>
        <input
          class="k-textbox"
          type="text"
          formControlName="DelegateOpenEdgeReleases"
          id="oereleases"
        />
      </div>
  </fieldset>

  <div class="k-form-field col-12 px-xl-0">
    <label for="learn">What would you like to learn at the conference?</label>
    <textarea
      class="k-textbox"
      type="text"
      formControlName="DelegateInterest"
      id="learn"
    ></textarea>
  </div>
  <div class="k-form-field col-12 px-xl-0">
    <label for="learn">Remarks</label>
    <textarea
      class="k-textbox"
      type="text"
      formControlName="DelegateRemarks"
      id="remarks"
    ></textarea>
  </div>
  <div class="k-form-field w-100 text-center py-3">
    <button
      [disabled]="loading || !form.valid || form.pristine"
      class="mx-auto col-12 k-button k-primary bg-cw-blue"
      (click)="submit()"
    >
      <span [hidden]="loading">Submit</span>
      <kendo-loader
        *ngIf="loading"
        [type]="'infinite-spinner'"
        themeColor="info"
        size="medium"
      >
      </kendo-loader>
    </button>
  </div>
</form>

<ng-template #regSuccessTemplate>
  <p>
    Thank you. You should have received an email with instructions on how to
    complete your registration.
  </p>
  <p>
    If you have not received this email within a few minutes, please check your
    spam / junk folder and mark
    <a href="mailto:poet-summit@consultingwerk.com" target="_blank"
      >poet-summit@consultingwerk.com</a
    >
    as not spam.
  </p>
  <p>
    If you experience issues, please contact us for support at
    <a href="mailto:poet-summit@consultingwerk.com" target="_blank"
      >poet-summit@consultingwerk.com</a
    >.
  </p>
</ng-template>
<ng-template #invalidUserNameTemplate>
  Username may not contain "@" or empty spaces.
</ng-template>
