import { Component } from "@angular/core";
import { DialogService } from "@progress/kendo-angular-dialog";
import { Router } from "@angular/router";
import { SignInModalComponent } from "./sign-in-modal/sign-in-modal.component";
import { UserProfile, UserService } from "./shared/user.service";
import { filter } from "rxjs/operators";
import { SafeResourceUrl } from "@angular/platform-browser";
import { QuizConfigService } from "./quiz/shared/config.service";
import { FeedbackService } from "./shared/feedback.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  viewProviders: [
    QuizConfigService
  ]
})
export class AppComponent {
  
  selectedMenuItem = "event";
  sessionContext: UserProfile;
  gravatarUrl: SafeResourceUrl;
  userInitials: string;
  authenticated: boolean;
  signingOut: boolean;
  isQuizActive: boolean;

  get isQuiz(): boolean {
    return window.location.pathname.includes('quiz');
  }

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private userService: UserService,
    private quizConfig: QuizConfigService,
    private feedbackService: FeedbackService,
  ) {}

  ngOnInit() {
    this.refreshSession();
    this.userService.profileUpdated.subscribe(() => {
      this.refreshSession();
    });
    this.userService.authenticated.subscribe((authenticated) => {
      this.displaySignedOutPromptIfNeeded(authenticated, this.authenticated);
      this.authenticated = authenticated;
      this.refreshSession();
    });
    this.quizConfig.getConfig()
        .subscribe(config => {
          const start = new Date(config.Client.StartOn);
          start.setHours(start.getHours() + (start.getTimezoneOffset() / 60));
          this.isQuizActive = new Date() >= start;
        }, err => {
          this.feedbackService.handleError(err);
        });
  }

  displaySignedOutPromptIfNeeded(newAuthState: boolean, oldAuthState: boolean) {
    if (!newAuthState && oldAuthState) {
      this.dialogService.open({
        title: this.signingOut ? "Sign Out" : "Session Expired",
        content: "You have been signed out.",
        actions: [
          {
            text: "OK",
          },
        ],
      });
    }
  }

  refreshSession() {
    this.userService.getUserProfile().subscribe((context) => {
      this.sessionContext = context;
    }, err => {
      this.feedbackService.handleError(err);
    });
  }
  selectMenuItem(itemHash: string) {
    this.selectedMenuItem = itemHash;
    this.router.navigateByUrl(`/main/${itemHash}`);
  }

  isMenuItemSelected(itemHash: string): boolean {
    return this.selectedMenuItem === itemHash;
  }

  signIn() {
    this.dialogService
      .open({
        title: "Sign In",
        content: SignInModalComponent,
      })
      .result.pipe(filter((result) => result === true))
      .subscribe(() => {
        this.refreshSession();
      });
  }

  signOut() {
    this.signingOut = true;
    this.userService.signOut().subscribe(() => {
      this.signingOut = false;
      this.refreshSession();
    });
  }

  
}
