import { Component, OnInit } from '@angular/core';
import { Participant } from '../shared/participant.model';
import { QuestionsService } from '../shared/questions.service';
import { UserService } from '../../shared/user.service';
import { FeedbackService } from '../../shared/feedback.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class SubmitComponent implements OnInit {

  participant: Participant;
  agreeToPrivacyStatement: boolean;
  agreeToUseName: boolean;

  constructor(private questionsService: QuestionsService,
              private router: Router,
              private userService: UserService,
              private feedbackService: FeedbackService) { }

  ngOnInit() {
    this.participant = {} as any;
    this.userService.getUserProfile()
        .subscribe(profile => {
          this.participant = {
            company: profile.DelegateCompany,
            fullName: profile.DelegateName,
            email: profile.DelegateEmail,
            jobTitle: profile.DelegateFunction,
          };
        }, err => {
          this.feedbackService.handleError(err);
        });
  }

  submit() {
    this.questionsService.questions.subscribe(questions => {
      console.log(questions);
      console.log(this.participant);
      this.questionsService.submitAnswers(this.participant, questions)
        .subscribe(() => {
          this.feedbackService.showSuccess('Thank you for participating! Stay tuned to find out who the winners will be!')
              .result.subscribe(() => this.router.navigate(['/main']));
        }, err => this.feedbackService.handleError(err));
    });
  }
}
