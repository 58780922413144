
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { QuizConfigService } from './config.service';

@Injectable()
export class DeadlineGuard implements CanActivate {


    constructor(private configService: QuizConfigService,
        private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.configService.getConfig()
            .pipe(
                map(config => {
                    if (!config.Client || !config.Client.Deadline) {
                        return true;
                    }
                    const deadline = new Date(config.Client.Deadline);
                    deadline.setHours(deadline.getHours() + (deadline.getTimezoneOffset() / 60));
                    console.log(deadline);
                    return deadline < new Date();
                }),
                map(isAfterDeadline => {
                    console.log('is after deadline: %s [%s]', isAfterDeadline, next.routeConfig.path);
                    if (isAfterDeadline && next.routeConfig.path !== 'end') {
                        console.log('path not end, redirect to end')
                        this.router.navigate(['/quiz/end']);
                        return false;
                    } else if (!isAfterDeadline && next.routeConfig.path === 'end') {
                        this.router.navigate(['/quiz/splash']);
                        return false;
                    }
                    return true;
                    // if (isAfterDeadline) {
                    //     console.log('redirect to end')
                    //     this.router.navigate(['/end']);
                    // }
                    // return !isAfterDeadline;
                })
            );
    }
}
