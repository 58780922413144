<div class="container">
  <h1>Terms & Conditions</h1>

  <p>
    These Terms and Conditions govern your relationship with
    www.consultingwerk.de website operated by Consultingwerk Ltd. Please read
    these Terms and Conditions carefully before using the site. Your access to
    and use of the site is conditioned on your acceptance of and compliance with
    these terms. These terms apply to all visitors, users and others who access
    or use the site. By accessing or using the site you agree to be bound by
    these terms. If you disagree with any part of the terms then you may not
    access the site.
  </p>

  <h2>Intellectual Property</h2>
  <p>
    The site and its original content, features and functionality are and will
    remain the exclusive property of Consultingwerk Ltd. and its licensors. The
    site is protected by copyright, trademark, and other laws of both the
    Germany and foreign countries. Our trademarks and trade dress may not be
    used in connection with any product or service without the prior written
    consent of Consultingwerk Ltd..
  </p>

  <h2>Links To Other Web Sites</h2>
  <p>
    Our site may contain links to third-party web sites or services that are not
    owned or controlled by Consultingwerk Ltd..Consultingwerk Ltd. has no
    control over, and assumes no responsibility for, the content, privacy
    policies, or practices of any third party web sites or services. You further
    acknowledge and agree that Consultingwerk Ltd. shall not be responsible or
    liable, directly or indirectly, for any damage or loss caused or alleged to
    be caused by or in connection with use of or reliance on any such content,
    goods or services available on or through any such web sites or services. We
    strongly advise you to read the terms and conditions and privacy policies of
    any third-party web sites or services that you visit.
  </p>

  <h2>Limitation Of Liability</h2>
  <p>
    In no event shall Consultingwerk Ltd., nor its directors, employees,
    partners, agents, suppliers, or affiliates, be liable for any indirect,
    incidental, special, consequential or punitive damages, including without
    limitation, loss of profits, data, use, goodwill, or other intangible
    losses, resulting from (i) your access to or use of or inability to access
    or use the Service; (ii) any conduct or content of any third party on the
    Service; (iii) any content obtained from the site; and (iv) unauthorized
    access, use or alteration of your transmissions or content, whether based on
    warranty, contract, tort (including negligence) or any other legal theory,
    whether or not we have been informed of the possibility of such damage, and
    even if a remedy set forth herein is found to have failed of its essential
    purpose.
  </p>

  <h2>Disclaimer</h2>
  <p>
    Your use of the site is at your sole risk. The site is provided on an "AS
    IS" and "AS AVAILABLE" basis. The Service is provided without warranties of
    any kind, whether express or implied, including, but not limited to, implied
    warranties of merchantability, fitness for a particular purpose,
    non-infringement or course of performance. Consultingwerk Ltd. its
    subsidiaries, affiliates, and its licensors do not warrant that a) the site
    will function uninterrupted, secure or available at any particular time or
    location; b) any errors or defects will be corrected; c) the site is free of
    viruses or other harmful components; or d) the results of using the site
    will meet your requirements.
  </p>

  <h2>Governing Law</h2>
  <p>
    These terms shall be governed and construed in accordance with the laws of
    North Rhine-Westphalia, Germany, without regard to its conflict of law
    provisions. Our failure to enforce any right or provision of these terms
    will not be considered a waiver of those rights. If any provision of these
    terms is held to be invalid or unenforceable by a court, the remaining
    provisions of these terms will remain in effect. These terms constitute the
    entire agreement between us regarding our site, and supersede and replace
    any prior agreements we might have between us regarding the site.
  </p>

  <h2>Changes</h2>
  <p>
    We reserve the right, at our sole discretion, to modify or replace these
    terms at any time. If a revision is material we will try to provide at least
    30 days notice prior to any new terms taking effect. What constitutes a
    material change will be determined at our sole discretion. By continuing to
    access or use our site after those revisions become effective, you agree to
    be bound by the revised terms. If you do not agree to the new terms, please
    stop using the site.
  </p>
</div>
