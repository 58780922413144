import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class RegistrationService {

    constructor(private http: HttpClient) {}

    registerUser(req: IUserRegistrationRequest): Observable<void> {
        return this.http.post<void>('https://poet-summit.org/public/web/Entities/Delegates/Register', req);
    }

    confirmEmail(confirmGuid: string): Observable<void> {
        return this.http.post<void>('https://poet-summit.org/public/web/Entities/Delegates/Confirm', { Value: confirmGuid });
    }
}

export interface IUserRegistrationRequest {
    DelegateName: string;
    DelegateEmail: string;
    DelegateCompany: string;
    DelegateInterest: string;
    DelegateOpenEdgeReleases: string;
    DelegateRemarks: string;
    DelegateFunction: string;
    DelegateCountry: string;
}