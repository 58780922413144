<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-lg-6 order-lg-2">
            <img class="column-img" src="../../assets/images/king-of-the-nerds.png">
        </div>
        <div class="col-12 col-lg-6 order-lg-1">
            <span class="section-title">
                <h1 class="text-center">POET Summit OpenEdge Nerd Quiz</h1>
            </span>
            <p class="section-text">Do you think you have what it takes to be crowned King of the OpenEdge Nerds? If
                your answer is yes, then
                we invite you to put that to the test between 08.11 - 10.11 2021 in our Trivia Quiz Competition and win
                awesome prizes. If you are truly worthy of the title, that is.
            </p>

        </div>

    </div>
    <div class="row mt-lg-5">
        <div class="col-12 col-lg-6">
            <img class="column-img" src="../../assets/images/google-nest-hub.jpg">
        </div>
        <div class="col-12 col-lg-6">
            <span class="section-title text-center">
                <h2>Spoils of The King</h2>
            </span>
            <p class="section-text">The crowned King of The OpenEdge Nerds shall be awarded a trusty Google Nest Hub to
                command His or Her Majesty's nerdy kingdom. This capable second-in-command will be able to do the King's
                bidding in many areas of the kingdom:</p>
            <ul>
                <li>
                    <p class="section-text">Inform the King of upcoming weather</p>
                </li>
                <li>
                    <p class="section-text">Command the King's fools to play amusing YouTube videos for the King's
                        entertainment</p>
                </li>
                <li>
                    <p class="section-text">Command the King's fools to play music for the King's entertainment</p>
                </li>
                <li>
                    <p class="section-text">Automate various tasks from the King's day to day life</p>
                </li>
                <li>
                    <p class="section-text">Command the Roomba cleaning staff to clean the King's quarters</p>
                </li>
                <li>
                    <p class="section-text">... and much more</p>
                </li>
            </ul>
        </div>

    </div>
    <div class="row">
        <div class="col-12 col-lg-6 order-lg-2">
            <img class="column-img" src="../../assets/images/stadia.jpg">

        </div>
        <div class="col-12 col-lg-6 order-lg-1">
            <span class="section-title text-center">
                <h2>The Lord of The Nerds' Prize</h2>
            </span>
            <p class="section-text">The second in command shall win a Stadia Premium Edition gaming controller to help him/her consolidate their war strategies:</p>
            <ul>
                <li>
                    <p class="section-text">Exercise their strategical thinking in various games to be prepared for the war at any time</p>
                </li>
                <li>
                    <p class="section-text">Play on any of their devices: laptop, tablet, phone or TV (it includes a Chromecast Ultra)</p>
                </li>
                <li>
                    <p class="section-text">Record their favorite moments using the "Capture" button</p>
                </li>
            </ul>
        </div>

    </div>
    <div class="row">
        <div class="col-12 col-lg-6">
            <img class="column-img" src="../../assets/images/google-nest-mini.jpg">

        </div>
        <div class="col-12 col-lg-6">
            <span class="section-title text-center">
                <h2>The Nerdy Knight Award</h2>
            </span>
            <p class="section-text">The Nerdy Knight will be awarded a mighty Google Nest Mini, which will allow him/her to:</p>
            <ul>
                <li>
                    <p class="section-text">Listen to their favorite chants or poems in the intimacy of their chamber</p>
                </li>
                <li>
                    <p class="section-text">Listen to the morning news before heading into the town to accomplish their daily duties</p>
                </li>
                <li>
                    <p class="section-text">Set alarms, reminders, timers to rest assured that they will not miss any callings from now on</p>
                </li>
                <li>
                    <p class="section-text">... and much more</p>
                </li>
            </ul>
        </div>

    </div>

    <div class="row">
        <span class="section-title text-center mt-2 mt-lg-5">
            <h2>Rules For The Aspiring King</h2>
        </span>
        <ul>
            <li><p class="section-text">The quiz consists of a number of multiple choice questions</p></li>
            <li><p class="section-text">Each question has exactly one correct answer</p></li>
            <li><p class="section-text">You may only move forward through questions</p></li>
            <li><p class="section-text">You have 30 seconds to answer each question</p></li>
            <li><p class="section-text">You may only take the quiz once</p></li>
            <li><p class="section-text">The participant with the most correct answers wins</p></li>
            <li><p class="section-text">If there is a draw between two or more participants, the winner will be selected randomly using random.org</p></li>
            <li><p class="section-text">Participating in the competition implies that you have read and agreed with our <a [routerLink]="'/privacy'" >privacy policy</a></p></li>
            <li><p class="section-text">Participating in the competition implies that you agree that your full name might be published by Consultingwerk Software Services on social media</p></li>
            <li><p class="section-text">Employees of Consultingwerk and their relatives are excluded</p></li>
            <li><p class="section-text">The winner will be announced on Wednesday at 18:00 CET</p></li>
        </ul>
    </div>

    <div class="row" *ngIf="isQuizActive">
        <span id="quiz-link" *ngIf="authenticated"><a [routerLink]="'/quiz'">Click here</a> to put your nerdy skills to the test!</span>
        <span id="signin-link" *ngIf="!authenticated"><a href="javascript:void()" (click)="signIn()">Sign In</a> to put your nerdy skills to the test!</span>
    </div>
</div>