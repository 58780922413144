import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Order, InvoiceInfo } from '../model/order.model';
import { Delegate } from '../model/delegate.model';
import { COUNTRIES } from './countries';

@Component({
  selector: 'app-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.css']
})

  export class OrderDialogComponent extends DialogContentBase implements OnInit {
    @ViewChild(NgForm, { static: true} ) form: NgForm;

    countries: string[];

    public invoice: InvoiceInfo = {
      CompanyName: '',
      FirstName: '',
      LastName: '',
      Address1: '',
      Address2: '',
      Address3: '',
      City: '',
      Country: 'Australia',
      PostCode: '',
      Email: '',
      Phone: ''
    }

    constructor(public dialog: DialogRef) {
        super(dialog);
    }

    ngOnInit() {
      this.countries = COUNTRIES;
    }

    public onCancelAction(): void {
        this.dialog.close({text: 'Cancel'});
    }

    public onConfirmAction(): void {
        this.dialog.close({text: 'Submit', data: this.invoice});
    }
  }
