<form class="k-form" #forgotPassword id="forgotPassword">
    <div class="kendo-form-field">
        <label for="newPassword">New Password</label>
        <input class="k-textbox" type="password" required [(ngModel)]="newPassword" id="newPassword" name="newPassword">
    </div>

    <div class="kendo-form-field">
        <label for="confirmedPassword">Confirm Password</label>
        <input class="k-textbox" type="password" required [(ngModel)]="confirmedPassword" id="confirmedPassword" name="confirmedPassword">
    </div>
    <div *ngIf="newPassword && confirmedPassword && newPassword !== confirmedPassword" class="k-form-field alert alert-danger mb-0">
        The password and the confirmed password do not match.
    </div>
</form>
<kendo-dialog-actions>
    <button
        [disabled]="!newPassword || !confirmedPassword"
        id="btn-submit"
        class="k-button k-primary"
        (click)="submit()"
        type="submit"
        form="forgotPassword"
        >
        <span>Submit</span>
    </button>
    <button class="k-button" type="button" (click)="cancel()">Cancel</button>
</kendo-dialog-actions>