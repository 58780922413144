import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuizRoutingModule } from './quiz-routing.module';
import { SplashComponent } from './splash/splash.component';
import { QuestionsComponent } from './questions/questions/questions.component';
import { QuestionViewerComponent } from './questions/question-viewer/question-viewer.component';
import { PassedDeadlineComponent } from './passed-deadline/passed-deadline.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { QuizConfigService } from './shared/config.service';
import { DeadlineGuard } from './shared/deadline.guard';
import { QuestionsAnsweredGuard } from './shared/questions-answered.guard';
import { QuestionsService } from './shared/questions.service';
import { RefreshGuard } from './shared/refresh.guard';
import { SharedModule } from '../shared/shared.module';
import { SubmitComponent } from './submit/submit.component';
import { TimerComponent } from './shared/timer/timer.component';


@NgModule({
  declarations: [
    SplashComponent,
    QuestionsComponent,
    QuestionViewerComponent,
    PassedDeadlineComponent,
    SubmitComponent,
    TimerComponent
  ],
  imports: [
    CommonModule,
    QuizRoutingModule,
    DialogModule,
    FormsModule,
    HttpClientModule,
    QuizRoutingModule,
    SharedModule
  ],
  providers: [
    DeadlineGuard,
    RefreshGuard,
    QuestionsAnsweredGuard,
    QuestionsService,
    QuizConfigService,
  ]
})
export class QuizModule { }
