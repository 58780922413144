
<div class="row">
    <button class="btn btn-link" *ngFor="let track of trackNames" [routerLink]="['/main/track-' + trackNamesEscaped[track]]">{{track}}</button>
</div>
<div class="my-5">
<div *ngFor="let track of trackNames" class="mt-2">
    <span class="section-text">
        <h1 class="text-center" [id]="'track-' + trackNamesEscaped[track]">{{track}}</h1>
    </span>
    <hr>
    <div *ngFor="let session of sessions[track] let i = index">
        <app-session-view [session]="session" [isAuthenticated]="isAuthenticated" (toggleFavorite)="toggleSessionFavorite(session, $event)"></app-session-view>
        <hr *ngIf="i < sessions[track].length - 1">
    </div>
</div>
</div>