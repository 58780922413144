import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DialogService } from "@progress/kendo-angular-dialog";
import { filter } from "rxjs/operators";
import { QuizConfigService } from "../quiz/shared/config.service";
import { FeedbackService } from "../shared/feedback.service";
import { UserService } from "../shared/user.service";
import { SignInModalComponent } from "../sign-in-modal/sign-in-modal.component";

@Component({
  selector: "nerd-quiz-section",
  templateUrl: "./nerd-quiz.component.html",
  styleUrls: ["./nerd-quiz.component.scss"],
})
export class NerdQuizComponent implements OnInit {
  authenticated: boolean;
  isQuizActive: boolean;

  constructor(
    private userService: UserService,
    private quizConfig: QuizConfigService,
    private dialogService: DialogService,
    private feedbackService: FeedbackService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userService.authenticated.subscribe((authenticated) => {
      this.authenticated = authenticated;
    });

    this.quizConfig.getConfig()
        .subscribe(config => {
          const start = new Date(config.Client.StartOn);
          start.setHours(start.getHours() + (start.getTimezoneOffset() / 60));
          this.isQuizActive = new Date() >= start;
        }, err => {
          this.feedbackService.handleError(err);
        });
  }

  signIn() {
    this.dialogService
      .open({
        title: "Sign In",
        content: SignInModalComponent,
      })
      .result.pipe(filter((result) => result === true))
      .subscribe(() => {
        this.router.navigateByUrl(`/quiz`);
      });
  }
}
