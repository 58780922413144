import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap, take, tap } from "rxjs/operators";
import { FeedbackService } from "./feedback.service";
import { UserService } from "./user.service";

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private userService: UserService, private feedbackService: FeedbackService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(
        req.clone({
          withCredentials: !req.url.includes('api/quiz'),
        })
      )
      .pipe(
        catchError((err) => {
          if (err.status === 401) {
          
            return this.userService.authenticated.pipe(
              take(1),
              tap((authenticated) => {
                if (authenticated) {
                  this.userService.authenticated.next(false);
                }
              }),
              switchMap(() => throwError(err))
            );
          }
          return throwError(err);
        })
      );
  }
}
