import { Component, OnInit } from '@angular/core';
import { DialogContentBase, DialogRef } from "@progress/kendo-angular-dialog";
import { FeedbackService } from '../shared/feedback.service';
import { UserService } from '../shared/user.service';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent extends DialogContentBase implements OnInit {

  email: string;
  loading = false;

  constructor(dialog: DialogRef, private userService: UserService, private feedbackService: FeedbackService) {
    super(dialog);
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialog.close();
  }

  submit() {
    this.loading = true;

    this.userService.forgotPassword(this.email).subscribe(() => {
      this.loading = false;

      this.dialog.close('success');
    }, (err) => {
      this.loading = false;
      this.feedbackService.handleError(err);
    })
  }

}
