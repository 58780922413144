import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class TimeZonesService {
  constructor(private http: HttpClient) {}

  getTimeZones(): Observable<ITimeZone[]> {
    return this.http
      .get<ITimeZone[]>("/assets/timezones_winter_aggregated.json");
  }
}

export interface ITimeZone {
  id: string;
  label: string;
  abbr: string;
  offset: string;
}
