import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { ISession, Session } from "../model/session.model";
import { ISchedule } from "./schedule.model";





@Injectable()
export class ScheduleService {

    readonly isMobileDevice: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(private http: HttpClient) {

    }

    getSchedule(authenticated: boolean): Observable<ISchedule> {
        return this.http.get<ISchedule>(`https://poet-summit.org/${authenticated ? 'registered' : 'public'}/web/Entities/Schedule`).pipe(
            map((sch: ISchedule) => {
                
                let days = sch.days.map((day) => {
                    const timeslots = day.timeslots.map(t => {
                        t.sessions = Object.keys(t).filter(k => k !== 'time' && k !== 'timezone').map(k => ({
                            roomId: k,
                            ...t[k]
                        }));
                        return t;
                    })
                    day.timeslots = timeslots
                    return day;
                    
                });
                sch.days = days;

                // try {
                //     const elevenThirty = sch.days[0].timeslots.find(ts => {
                //         let session_05 = ts.sessions.find(session => session.Session_ID === '05');
                //         if (session_05) {
                //             return ts;
                //         } else return null;
                //     });
                //     const elevenThirtySession = elevenThirty.sessions[0];

                //     let correctedTime: string;

                //     const elevenFifteen = sch.days[0].timeslots.find(ts => {
                //         const session_17 = ts.sessions.find(session => session.Session_ID === '17');
                //         const session_31 = ts.sessions.find(session => session.Session_ID === '31');
                //         if (session_17 && session_31) {
                //             correctedTime = session_17.time;
                //             return ts;
                //         } else return null;
                //     });
                //     sch.days[0].timeslots = sch.days[0].timeslots.filter(ts => {
                //         let session_05 = ts.sessions.find(session => session.Session_ID === '05');
                //         if (!session_05) {
                //             return ts;
                //         } else {
                //             return null;
                //         }
                //     });
                    
                //     elevenThirtySession.time = correctedTime;
                //     elevenFifteen.sessions.push(elevenThirtySession);
                //     elevenFifteen[2] = elevenThirtySession;
                // } catch (err) {
                //     console.warn(err);
                // }
                return sch;
            })
        );
    }

    setIsMobileDevice(val: boolean) {
        this.isMobileDevice.next(val);
    }



}