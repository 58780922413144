import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { Question } from './question.model';
import { tap, map } from 'rxjs/operators';
import { Participant } from './participant.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  readonly currentQuestion: ReplaySubject<Question> = new ReplaySubject(1);
  readonly currentQuestionIndex: ReplaySubject<number> = new ReplaySubject(1);
  readonly questions: ReplaySubject<Question[]> = new ReplaySubject(1);
  readonly quizStopped: EventEmitter<void> = new EventEmitter();

  private _questions: Question[];
  private _currentQuestionIndex: number;

  get allQuestionsAnswered(): boolean {
    if (!this._questions) {
      return;
    }
    for (const q of this._questions) {
      if (!q.answer) {
        return false;
      }
    }
    return true;
  }

  constructor(private http: HttpClient) {
    this.currentQuestionIndex.next(-1);
  }

  init() {
    this.currentQuestionIndex.subscribe(i => {
      this._currentQuestionIndex = i;
      this.currentQuestion.next(this._questions[i]);
    });
  }

  loadQuestions(): Observable<Question[]> {
    return this.http.get<Question[]>('https://poet-summit.org/api/quiz/questions')
      .pipe(
        tap(q => console.log('q', q)),
        // map(questions => {
        //   for (const q of questions) {
        //     q.text = q.text.replace(/\$n/g, '<br/>');
        //   }
        //   return questions;
        // }),
        tap(questions => (this._questions = questions) && this.questions.next(questions)),
        tap(() => this.init())
      );
  }

  startQuiz() {
    this.askQuestion(0);
  }

  askQuestion(index: number) {
    this.currentQuestionIndex.next(index);
  }

  nextQuestion() {
    this.askQuestion(this._currentQuestionIndex + 1);
  }

  previousQuestion() {
    this.askQuestion(this._currentQuestionIndex - 1);
  }

  stopQuiz() {
    this.quizStopped.next();
    this.currentQuestionIndex.next(-1);
  }

  submitAnswers(participant: Participant, answers: Question[]): Observable<void> {
    return this.http.post<void>('https://poet-summit.org/api/quiz/questions/answer', {
      participant, answers
    })
      .pipe(
        tap(() => this.stopQuiz())
      );
  }

}
