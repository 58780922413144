<form class="k-form" #signin id="forgotPassword">
    <p>Please enter the e-mail address associated with your account and we will send you a link to reset your password.</p>
    <div class="kendo-form-field">
        <label for="username">E-mail</label>
        <input class="k-textbox" type="text" required [(ngModel)]="email" id="email" name="email">
    </div>
</form>
<kendo-dialog-actions>
    <button
        [disabled]="!email || loading"
        id="btn-submit"
        class="k-button k-primary"
        (click)="submit()"
        type="submit"
        form="signin"
        >
        <span [hidden]="loading">Submit</span>
        <kendo-loader
            *ngIf="loading"
            [type]="'infinite-spinner'"
            themeColor="info"
            size="small"
        > 
        </kendo-loader>
    </button>
    <button class="k-button" type="button" (click)="cancel()">Cancel</button>
</kendo-dialog-actions>