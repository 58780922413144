import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable() 
export class UserService {

  constructor(private http: HttpClient) { }

  login(username: string, password: string): Observable<void> {
    return this.http.post<void>('/auth/login', {
      username, password
    });
  }

  isLoggedIn(): Observable<boolean> {
    return this.http.get<boolean>('/auth/ping')
        .pipe(
          map(
            r => r,
            err => false
          )
        );
  }

  logout(): Observable<void> {
    return this.http.delete<void>('/auth/logout');
  }

}
