<div class="container h-90 d-flex flex-column">
    <div class="flex-0 mt-3">
        <div class="day-buttons row">
            <a class="col-6" (click)="selectedDate = 0" [ngClass]="{ 'selected': selectedDate===0 }">TUESDAY</a>
            <a class="col-6" (click)="selectedDate = 1"
                [ngClass]="{ 'selected': selectedDate===1 }">WEDNESDAY</a>
        </div>
    </div>
    <div class="row mx-auto my-5 pb-5 w-100" *ngIf="loading">
        <kendo-loader
            class="mx-auto"
            type="converging-spinner"
            size="large">
        </kendo-loader>
    </div>

    <div class="flex-1" style="margin-bottom: 66px;" *ngIf="schedule && !loading">
        <div>
            <day-view [timeslots]="schedule.days[selectedDate].timeslots" [day]="selectedDate" [rooms]="schedule.rooms" (toggleFavorite)="toggleSessionFavorite($event)"></day-view>
        </div>
    </div>
    <kendo-resize-sensor (resize)="setIsMobileDevice()"></kendo-resize-sensor>
</div>