import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { AuthGuard } from "./auth.guard";
import { AuthorizationInterceptor } from "./auth.interceptor";
import { FeedbackService } from "./feedback.service";
import { TimezonesDropdownComponent } from "./timezones/timezones-dropdown/timezones-dropdown.component";
import { TimeZonesService } from "./timezones/timezones.service";
import { UserService } from "./user.service";
import { SessionViewComponent } from './sessions/session-view/session-view.component';
import { CommonModule } from "@angular/common";
import { SessionDescriptionPipe } from "./sessions/session-description.pipe";
import { SessionsFilterPipe } from "./sessions/sessions.filter.pipe";
import { RouterModule } from "@angular/router";
import { HyperlinkConvertPipe } from "./url.pipe";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        DropDownsModule,
        RouterModule
    ],
    declarations: [
        TimezonesDropdownComponent,
        SessionViewComponent,
        SessionDescriptionPipe,
        SessionsFilterPipe,
        HyperlinkConvertPipe
    ],
    exports: [
        TimezonesDropdownComponent,
        SessionViewComponent,
        SessionDescriptionPipe,
        SessionsFilterPipe,
        HyperlinkConvertPipe
    ]
})
export class SharedModule {

    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                FeedbackService,
                TimeZonesService,
                UserService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthorizationInterceptor,
                    multi: true
                },
                AuthGuard,
                SessionDescriptionPipe,
                AuthorizationInterceptor,
                SessionsFilterPipe,
                HyperlinkConvertPipe
            ]
        }
    }
}