import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'HyperlinkConvert'
})
export class HyperlinkConvertPipe implements PipeTransform {
    transform(value: string): string {
        if (!value || value === '') {
            return value;
        }
        const urls = Array.from(value.match(URL_REGEX) || []);
        for (const url of urls) {
            value = value.replace(url, `<a href="${url}" target="_blank">${url}</a>`);
        }
        return value;
    }
}

export const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;