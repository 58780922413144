import { Component, OnInit } from "@angular/core";
import { QuizConfigService } from "../../shared/config.service";
import { Config } from "../../shared/config.model";
import { FeedbackService } from "../../../shared/feedback.service";

@Component({
  selector: "app-hello-modal",
  templateUrl: "./hello-modal.component.html",
  styleUrls: ["./hello-modal.component.css"],
})
export class HelloModalComponent implements OnInit {
  config: Config;

  constructor(private configService: QuizConfigService, private feedbackService: FeedbackService) {}

  ngOnInit() {
    this.configService.getConfig().subscribe((config) => {
      setTimeout(() => {
        this.config = config;
      });
    }, err => {
      this.feedbackService.handleError(err);
    });
  }
}
