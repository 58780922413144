export interface ISession {
  Status: string;
  Name: string | string[];
  Company: string | string[];
  Nationaliy: string;
  Email: string;
  EventID?: string;
  StreamSecret?: string;
  Track: string;
  Onsite_Remote: string;
  Session_Title: string;
  Session_Description: string;
  Missing_Session_Description: boolean;
  Time_constraints: string;
  Session_Duration: number;
  T_Shirt_Size: string;
  Address: string;
  Phone_Number: string;
  Kommentar: string;
  Session_ID?: string;
  Session_Speakers: ISpeaker[];
  Favorite?: boolean;
  Session_Tags: string[];
  timezone: string;
  time: string;
  date: string;
}

export interface ISpeaker {
  Company: string;
  Email: string;
  Name: string;
  Nationality: string;
  SpeakerID: string;
}

export class Session implements ISession {
  Status: string;
  Name: string | string[];
  Company: string | string[];
  Nationaliy: string;
  Email: string;
  EventID?: string;
  StreamSecret?: string;
  Track: string;
  Onsite_Remote: string;
  Session_Title: string;
  Session_Description: string;
  Missing_Session_Description: boolean;
  Time_constraints: string;
  Session_Duration: number;
  T_Shirt_Size: string;
  Address: string;
  Phone_Number: string;
  Kommentar: string;
  Session_Display: string;
  Favorite_Session?: boolean;
  Session_Tags: string[];
  date: string;
  time: string;
  timezone: string;
  timezoneDisplay: string;
  constructor(model: ISession) {
    Object.assign(this, model);
    let display = (model.Session_Speakers?.length ? (model.Session_Speakers.map(s => s.Name).join(' & ') + ', ' + model.Session_Speakers.map(s => s.Company).join(' / ') + ', ') : '') + `${model.Session_Duration} minutes`;
    this.Session_Display = display;
    this.Missing_Session_Description = !this.Session_Description;

    this.timezoneDisplay = model.timezone.replace(/\|/g, ' / ')
    // if (typeof model.Name !== 'string') {
    //   const names: string[] = model.Name as string[];
    //   const companies: string[] = model.Company as string[];
    //   let display = '';
    //   for (const i in names) {
    //     display += `${names[i]}, ${companies[i]}, `
    //   }
    //   display += model.Session_Duration + ' minutes'
    //   this.Session_Display = display;
    // } else {
    //   this.Session_Display = `${model.Name}, ${model.Company}, ${model.Session_Duration} minutes`
    // }
  }
  Session_ID?: string;
  Session_Speakers: ISpeaker[];
}
