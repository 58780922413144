
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { QuestionsService } from './questions.service';
import { map } from 'rxjs/operators';

@Injectable()
export class QuestionsAnsweredGuard implements CanActivate {


    constructor(private questionsService: QuestionsService,
                private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.questionsService.currentQuestionIndex
            .pipe(
                map(index => {
                    console.log(index);
                    if (index < 0) {
                        this.router.navigate(['/splash']);
                    }
                    return index >= 0;
                })
            );
    }
}
