import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DialogCloseResult, DialogContentBase, DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { ForgotPasswordModalComponent } from "../forgot-password-modal/forgot-password-modal.component";
import { FeedbackService } from "../shared/feedback.service";
import { UserService } from "../shared/user.service";
@Component({
  selector: "app-sign-in-modal",
  templateUrl: "./sign-in-modal.component.html",
  styleUrls: ["./sign-in-modal.component.scss"],
})
export class SignInModalComponent extends DialogContentBase implements OnInit {
  username: string;
  password: string;
  loading = false;
  errorMessage: string;

  constructor(dialog: DialogRef, private userService: UserService, private dialogService: DialogService, private feedbackService: FeedbackService) {
    super(dialog);
  }

  ngOnInit() {}

  cancel() {
    this.dialog.close();
  }

  submit() {
    this.loading = true;
    this.userService.signIn(this.username, this.password).subscribe(
      () => {
        this.dialog.close(true);
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        if (err.status === 401) {
          return this.errorMessage = 'Invalid credentials'
        }
        this.errorMessage = err?.error?.message || 'There was a problem while signing you in. Please try again later.'
      }
    );
  }

  openForgotPasswordModal() {
      this.dialog.close();
      this.dialogService
        .open({
          title: "Forgot Password",
          content: ForgotPasswordModalComponent,
        })
        .result
        .subscribe((result) => {
          if (result instanceof DialogCloseResult) {
            return;
          }
          this.feedbackService.showSuccess('You will receive an email with instructions of how to reset your password.');
        });
  }
}
