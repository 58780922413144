<div class="container">
  <h1>Privacy Policy</h1>

  <p>
    This page informs you of our policies regarding the collection, use, and
    disclosure of personal data when you use our website and the choices you
    have associated with that data. We use your data to provide and improve the
    site. By using the website, you agree to the collection and use of
    information in accordance with this policy.
  </p>

  <h2>Information Collection And Use</h2>
  <p>
    We collect several different types of information for various purposes to
    provide and improve our site for you.
  </p>

  <h2>Types of Data Collected</h2>

  <div>
    <h3>Personal Data</h3>
    <p>
      While using our site, we may ask you to provide us with certain personally
      identifiable information that can be used to contact or identify you.
      Personally identifiable information may include, but is not limited to:
      Email address, First name and last name, Phone number, Address, State,
      Province, ZIP/Postal code, City, Cookies and Usage Data. We may use your
      Personal Data to contact you with newsletters, marketing or promotional
      materials and other information that may be of interest to you. You may
      opt out of receiving any, or all, of these communications from us by
      following the unsubscribe link or instructions provided in any email we
      send.
    </p>

    <h3>Usage Data</h3>
    <p>
      We may also collect information how the website is accessed and used. This
      Usage Data may include information such as your computer's Internet
      Protocol address (e.g. IP address), browser type, browser version, the
      pages of our site that you visit, the time and date of your visit, the
      time spent on those pages, unique device identifiers and other diagnostic
      data.
    </p>

    <h3>Tracking & Cookies Data</h3>
    <p>
      We use cookies and similar tracking technologies to track the activity on
      our site and hold certain information. Cookies are files with small amount
      of data which may include an anonymous unique identifier. Cookies are sent
      to your browser from a website and stored on your device. Tracking
      technologies also used are beacons, tags, and scripts to collect and track
      information and to improve and analyze our site. You can instruct your
      browser to refuse all cookies or to indicate when a cookie is being sent.
      However, if you do not accept cookies, you may not be able to use some
      portions of our Site.
    </p>

    <ul aria-label="Examples of Cookies we use:">
      <li>
        <strong>Session Cookies.</strong> We use Session Cookies to operate our
        site.
      </li>
      <li>
        <strong>Preference Cookies.</strong> We use Preference Cookies to
        remember your preferences and various settings.
      </li>
      <li>
        <strong>Security Cookies.</strong> We use Security Cookies for security
        purposes.
      </li>
    </ul>

    <h2>Use of Data</h2>
    <ul aria-label="POET Summit uses the collected data for various purposes:">
      <li>To provide and maintain our site</li>
      <li>To notify you about changes to our site</li>
      <li>
        To allow you to participate in interactive features of our site when you
        choose to do so
      </li>
      <li>To provide customer support</li>
      <li>
        To gather analysis or valuable information so that we can improve our
        site
      </li>
      <li>To monitor the usage of our site</li>
      <li>To detect, prevent and address technical issues</li>
      <li>
        To provide you with news, special offers and general information about
        other goods, sites and events which we offer that are similar to those
        that you have already purchased or enquired about unless you have opted
        not to receive such information
      </li>
    </ul>

    <h2>Retention of Data</h2>
    <p>
      Consultingwerk Ltd. will retain your Personal Data only for as long as is
      necessary for the purposes set out in this Privacy Policy. We will retain
      and use your Personal Data to the extent necessary to comply with our
      legal obligations (for example, if we are required to retain your data to
      comply with applicable laws), resolve disputes, and enforce our legal
      agreements and policies.
    </p>
    <p>
      Consultingwerk Ltd. will also retain Usage Data for internal analysis
      purposes. Usage Data is generally retained for a shorter period of time,
      except when this data is used to strengthen the security or to improve the
      functionality of our site, or we are legally obligated to retain this data
      for longer time periods.
    </p>

    <h2>Transfer of Data</h2>
    <p>
      Your information, including Personal Data, may be transferred to — and
      maintained on — computers located outside of your state, province, country
      or other governmental jurisdiction where the data protection laws may
      differ than those from your jurisdiction.
    </p>
    <p>
      If you are located outside Germany and choose to provide information to
      us, please note that we transfer the data, including Personal Data, to
      Germany and process it there. Your consent to this Privacy Policy followed
      by your submission of such information represents your agreement to that
      transfer. Consultingwerk Ltd. will take all steps reasonably necessary to
      ensure that your data is treated securely and in accordance with this
      Privacy Policy and no transfer of your Personal Data will take place to an
      organization or a country unless there are adequate controls in place
      including the security of your data and other personal information.
    </p>

    <h2>Disclosure of Data</h2>
    <h3>Legal Requirements</h3>
    <ul
      aria-label="Consultingwerk Ltd. may disclose your Personal Data in the good faith belief that such action is necessary to:"
    >
      <li>To comply with a legal obligation</li>
      <li>
        To protect and defend the rights or property of Consultingwerk Ltd.
      </li>
      <li>
        To prevent or investigate possible wrongdoing in connection with the
        site
      </li>
      <li>To protect the personal safety of users of the site or the public</li>
      <li>To protect against legal liability</li>
    </ul>

    <h2>Security of Data</h2>
    <p>
      The security of your data is important to us, but remember that no method
      of transmission over the Internet, or method of electronic storage is 100%
      secure. While we strive to use commercially acceptable means to protect
      your Personal Data, we cannot guarantee its absolute security.
    </p>

    <h2>Your Rights</h2>

    <p>
      Consultingwerk Ltd. aims to take reasonable steps to allow you to correct,
      amend, delete, or limit the use of your Personal Data. Whenever made
      possible, you can update your Personal Data directly within your account
      settings section. If you are unable to change your Personal Data, please
      contact us to make the required changes. If you wish to be informed what
      Personal Data we hold about you and if you want it to be removed from our
      systems, please contact us.
    </p>

    <ul aria-label="In certain circumstances, you have the right:">
      <li>
        To access and receive a copy of the Personal Data we hold about you
      </li>
      <li>To rectify any Personal Data held about you that is inaccurate</li>
      <li>To request the deletion of Personal Data held about you</li>
    </ul>

    <p>
      You have the right to data portability for the information you provide to
      Consultingwerk Ltd.. You can request to obtain a copy of your Personal
      Data in a commonly used electronic format so that you can manage and move
      it. Please note that we may ask you to verify your identity before
      responding to such requests.
    </p>

    <h2>Site Providers</h2>

    <p>
      We may employ third party companies and individuals to facilitate our
      site, to provide the site on our behalf, to perform site-related services
      or to assist us in analyzing how our site is used. These third parties
      have access to your Personal Data only to perform these tasks on our
      behalf and are obligated not to disclose or use it for any other purpose.
    </p>

    <h2>Links To Other Sites</h2>
    <p>
      Our site may contain links to other sites that are not operated by us. If
      you click on a third party link, you will be directed to that third
      party's site. We strongly advise you to review the Privacy Policy of every
      site you visit. We have no control over and assume no responsibility for
      the content, privacy policies or practices of any third party sites or
      sites.
    </p>

    <h2>Changes To This Privacy Policy</h2>
    <p>
      We may update our Privacy Policy from time to time. We will notify you of
      any changes by posting the new Privacy Policy on this page. We will let
      you know via email and/or a prominent notice on our site, prior to the
      change becoming effective and update the "effective date" at the top of
      this Privacy Policy. You are advised to review this Privacy Policy
      periodically for any changes. Changes to this Privacy Policy are effective
      when they are posted on this page.
    </p>
  </div>
</div>
