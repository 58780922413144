<table [ngClass]="isMobileDevice ? 'mobile' : 'not-mobile table table-striped'" *ngIf="!isMobileDevice">
    <thead class="thead-light">
        <tr>
            <th class="hour-cell"></th>
            <th scope="col" *ngFor="let room of rooms">{{room}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let timeslot of timeslots" [ngClass]="{'social': timeslot.sessions[0].Session_Speakers.length === 0 } ">
            <td class="hour-cell">
                <p class="session-date">{{timeslot.sessions[0].date}}</p>
                <p class="session-time">{{timeslot.sessions[0].time}}</p>
                <p class="session-timezone">{{timeslot.sessions[0].timezoneDisplay}}</p>
            </td>
            <td *ngFor="let room of rooms let i = index" [attr.colspan]="timeslot.sessions[0].Session_Speakers.length === 0 ? 3 : 1">
                <div *ngIf="timeslot[(i+1) + '']" [id]="'session-' + timeslot[(i+1) + ''].Session_ID" >
                    <p class="session-title">
                        <span class="mr-2 session-star" *ngIf="authenticated" (click)="toggleFavorite.next({session: timeslot[(i+1) + ''], isFavorite: !timeslot[(i+1) + '']?.Favorite_Session })">
                            <em [class]="'fa fa-star' + (timeslot[(i+1) + ''].Favorite_Session ? '' : '-o')"></em>
                        </span>
                        {{timeslot[(i+1) + ''].Session_Title}}
                    </p>
                    <div class="w-100 text-center">
                        <p class="session-duration">
                            <span *ngIf="!authenticated || !(timeslot[(i + 1) + ''].StreamSecret && timeslot[(i + 1) + ''].EventID)">
                                {{timeslot[(i+1) + ''].Session_Duration}} min
                            </span>
                            <a class="k-button k-button-outline" *ngIf="authenticated && timeslot[(i + 1) + ''].StreamSecret && timeslot[(i + 1) + ''].EventID" [routerLink]="['/stream']" [queryParams]="{ sessionId: timeslot[(i + 1) + ''].Session_ID, eventID: timeslot[(i + 1) + ''].EventID, streamID: timeslot[(i + 1) + ''].StreamSecret }">
                                <span class="k-icon k-i-play mr-2"></span>
                                View Presentation ({{timeslot[(i+1) + ''].Session_Duration}} min)
                            </a>
                        </p>

                    </div>
                    
                    <br>
                    
                    <a *ngFor="let speaker of timeslot[(i+1) + ''].Session_Speakers" class="speaker-link mx-1 my-1"
                        [routerLink]="'/sessions'"
                        [queryParams]="{ speaker: speaker.SpeakerID }"
                    >{{speaker.Name}}<span *ngIf="speaker.Company"> / {{speaker.Company}}</span></a>
                </div>
            </td>
        </tr>
    </tbody>
</table>

<div *ngIf="isMobileDevice" [ngClass]="isMobileDevice ? 'mobile' : 'not-mobile table table-striped'">
    <table class="table hours-table table-stripped">
        <thead>
            <th class="hour-cell"></th>
        </thead>
        <tbody>
            <tr *ngFor="let timeslot of timeslots" [ngClass]="{'social': timeslot.sessions[0].Session_Speakers.length === 0 }">
                <td class="hour-cell" scope="row">
                    <p class="session-date">{{timeslot.sessions[0].date}}</p>
                    <p class="session-time">{{timeslot.sessions[0].time}}</p>
                    <p class="session-timezone">{{timeslot.sessions[0].timezoneDisplay}}</p>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="room-container">
    <div class="carousel slide" [attr.id]="'carousel'+ day" data-interval="false" data-wrap="false">
        <ul class="carousel-indicators">
            <li [attr.data-target]="'#carousel' + day" data-slide-to="0" class="active"></li>
            <li [attr.data-target]="'#carousel' + day" data-slide-to="1"></li>
            <li [attr.data-target]="'#carousel' + day" data-slide-to="2"></li>
        </ul>
        <div class="carousel-inner">
            <ng-container *ngFor="let room of rooms let i = index">
                <div [ngClass]="{'carousel-item': true, 'active': i===selectedRoom }">
                    <table class="table sessions-table table-stripped">
                        <thead class="thead-light">
                           <colgroup>
                                <col width="20px">
                            </colgroup>
                            <tr>
                                <th scope="col">
                                    <span>{{room}}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let timeslot of timeslots" [ngClass]="{'social': timeslot.sessions[0].Session_Speakers.length === 0 } ">
                                <td [attr.colspan]="timeslot.sessions[0].Session_Speakers.length === 0 ? 3 : 1">
                                    <div *ngIf="timeslot[(i+1) + '']" [id]="'session-' + timeslot[(i+1) + ''].Session_ID">
                                        <p class="session-title">
                                            <span class="mr-2 session-star" *ngIf="authenticated" (click)="toggleFavorite.next({session: timeslot[(i+1) + ''], isFavorite: !timeslot[(i+1) + '']?.Favorite_Session })">
                                                <em [class]="'fa fa-star' + (timeslot[(i+1) + ''].Favorite_Session ? '' : '-o')"></em>
                                            </span>
                                            {{timeslot[(i+1) + ''].Session_Title}}
                                        </p>
                                        <div class="w-100 text-center">
                                            <p class="session-duration">
                                                <span *ngIf="!authenticated || !(timeslot[(i + 1) + ''].StreamSecret && timeslot[(i + 1) + ''].EventID)">
                                                    {{timeslot[(i+1) + ''].Session_Duration}} min
                                                </span>
                                                <a class="k-button k-button-outline" *ngIf="authenticated && timeslot[(i + 1) + ''].StreamSecret && timeslot[(i + 1) + ''].EventID" [routerLink]="['/stream']" [queryParams]="{ sessionId: timeslot[(i + 1) + ''].Session_ID, eventID: timeslot[(i + 1) + ''].EventID, streamID: timeslot[(i + 1) + ''].StreamSecret }">
                                                    <span class="k-icon k-i-play mr-2"></span>
                                                    View Presentation ({{timeslot[(i+1) + ''].Session_Duration}} min)
                                                </a>
                                            </p>
                    
                                        </div>
                                        <a *ngFor="let speaker of timeslot[(i+1) + ''].Session_Speakers" class="speaker-link mx-1 my-1 "
                                            [routerLink]="'/sessions'"
                                            [queryParams]="{ speaker: speaker.SpeakerID }"
                                        >{{speaker.Name}} / {{speaker.Company}}</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>

        <a class="carousel-control-prev" [attr.href]="'#carousel' + day" data-slide="prev">
            <span class="carousel-control-prev-icon"></span>
        </a>
        <a class="carousel-control-next" [attr.href]="'#carousel' + day" data-slide="next">
            <span class="carousel-control-next-icon"></span>
        </a>
    </div>
    </div>
</div>
