<div class="navigator-wrap d-flex flex-column h-100">
    <div class="question-text text-left mb-2 text-cw-gray" [innerHTML]="questionHtml">
    </div>
    <div [ngSwitch]="currentQuestion?.type" style="flex-grow:100">
        <form class="k-form-inline h-100 w-100 choice mt-5" *ngSwitchCase="'choice'">
            <div class="k-form-field w-100 d-block">
                <ng-container *ngFor="let choice of currentQuestion.choices; let i=index">
                    <input
                        type="radio"
                        [name]="'q_a'"
                        id="q_a"
                        class="k-radio"
                        [value]="choice"
                        [(ngModel)]="currentQuestion.answer">
                    <label
                        class="k-radio-label mb-3 text-cw-gray" (click)="currentQuestion.answer = choice">{{choice}}</label>
                </ng-container>
            </div>
        </form>
        <form class="k-form-inline h-100 w-100" *ngSwitchCase="'open'">
            <div class="k-form-field h-100">
                <textarea
                    placeholder="Your answer here"
                    class="k-textarea w-100 h-100 text-cw-gray"
                    [(ngModel)]="currentQuestion.answer"
                    name="q_a">
                </textarea>
            </div>
        </form>
    </div>
</div>
