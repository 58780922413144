import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../../shared/feedback.service';
import { QuizConfigService } from '../shared/config.service';

@Component({
  selector: 'app-passed-deadline',
  templateUrl: './passed-deadline.component.html',
  styleUrls: ['./passed-deadline.component.scss']
})
export class PassedDeadlineComponent implements OnInit {

  message: string;

  constructor(private configService: QuizConfigService, private feedbackService: FeedbackService) { }

  ngOnInit() {
    this.configService.getConfig()
      .subscribe(config => {
        this.message = config.Client.PassedDeadlineMessage || 
        'We thank you for your interest, however due to organisational reasons, we cannot accept new entries.'
      }, err => {
        this.feedbackService.handleError(err);
      });
  }

}
