import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CodeOfConductComponent } from "./code-of-conduct/code-of-conduct.component";
import { ImprintComponent } from "./imprint/imprint.component";
import { MainContentComponent } from "./main-content/main-content.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { QuizModule } from "./quiz/quiz.module";
import { ScheduleComponent } from "./schedule/schedule.component";
import { AuthGuard } from "./shared/auth.guard";
import { TermsOfUseComponent } from "./terms-of-use/terms-of-use.component";

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "main" },
  { path: "main", component: MainContentComponent },
  { path: "main/:hash", component: MainContentComponent },
  { path: "imprint", component: ImprintComponent },
  { path: "privacy", component: PrivacyPolicyComponent },
  { path: "terms-of-use", component: TermsOfUseComponent },
  { path: "code-of-conduct", component: CodeOfConductComponent },
  { path: "quiz", loadChildren: () => QuizModule },
  {
    path: "register",
    loadChildren: () =>
      import("./register/register.module").then((m) => m.RegisterModule),
  },
  {
    path: "confirm",
    loadChildren: () =>
      import("./confirm/confirm.module").then((m) => m.ConfirmModule),
  },
  {
    path: "profile",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./profile/profile.module").then((m) => m.ProfileModule),
  },
  { path: 'my-sessions', loadChildren: () => import('./my-sessions/my-sessions.module').then(m => m.MySessionsModule) },
  { path: 'sessions', loadChildren: () => import('./sessions-overview/sessions-overview.module').then(m => m.SessionsOverviewModule) },
  { path: 'schedule', component: ScheduleComponent },
  { path: 'quiz', loadChildren: () => import('./quiz/quiz.module').then(m => m.QuizModule) },
  { path: 'resetpassword', 
    loadChildren: () => import("./reset-password/reset-password.module").then((m) => m.ResetPasswordModule),
  },
  { path: 'stream', loadChildren: () => import('./streaming/streaming.module').then(m => m.StreamingModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
