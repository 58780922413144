import { Component, OnInit } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModalComponent extends DialogContentBase implements OnInit {

  newPassword: string;
  confirmedPassword: string;
  errorMessage: string;
  token: string;

  constructor(dialog: DialogRef) {
    super(dialog);
  }

  ngOnInit() {}

  cancel() {
    this.dialog.close();
  }

  submit() {
    this.dialog.close({
      password: this.newPassword
    })
  }

}
