import { Component, OnInit, Input, OnChanges, NgZone, ViewChild } from '@angular/core';
import { Question } from '../../shared/question.model';
import { take } from 'rxjs/operators';
import { TimerComponent } from '../../shared/timer/timer.component';

@Component({
  selector: 'app-question-viewer',
  templateUrl: './question-viewer.component.html',
  styleUrls: ['./question-viewer.component.css']
})
export class QuestionViewerComponent implements OnInit, OnChanges {

  @Input()
  currentQuestion: Question;

  get questionHtml(): string {
    if (!this.currentQuestion) {
      return '';
    }
    return this.currentQuestion.text.replace(/\\n/g, '<br/>')
  }
  constructor(private zone: NgZone) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.zone.onStable
      .pipe(take(1))
      .subscribe(() => {
        const textarea = document.querySelector('textarea');
        if (textarea) {
          textarea.focus();
          if (!!this.currentQuestion && !!this.currentQuestion.answer) {
            textarea.select();
          }
        }
        
      });
      
  }

}
