import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { CloudOptions, CloudData } from 'angular-tag-cloud-module';
import { map } from 'rxjs/operators';
import { SessionsService } from '../sessions/sessions.service';
import { FeedbackService } from '../shared/feedback.service';
import { getAvailableTagsFromSessions, getTagOccurrencesFromSessions, TagOccurrence } from '../shared/sessions/util';

@Component({
  selector: 'app-tag-cloud',
  templateUrl: './tag-cloud.component.html',
  styleUrls: ['./tag-cloud.component.scss']
})
export class TagCloudComponent implements OnInit, AfterViewInit {

  @ViewChild(TagCloudComponent) tagCloud: TagCloudComponent;

  options: CloudOptions = {
    // if width is between 0 and 1 it will be set to the width of the upper element multiplied by the value
    // width: 1000,
    // if height is between 0 and 1 it will be set to the height of the upper element multiplied by the value
    // height: 400,
    // width: 0.9,
    overflow: false,
    realignOnResize: true,
    randomizeAngle: true,
    strict: true,
  };

  data: CloudData[] = [
  ];

  constructor(private sessionsService: SessionsService,
              private elementRef: ElementRef<HTMLElement>,
              private changeDetector: ChangeDetectorRef,
              private feedbackService: FeedbackService) { }

  ngOnInit(): void {
    this.sessionsService
        .getSessionsPasoe()
        .pipe(
          map(sessions => getTagOccurrencesFromSessions(sessions))
        )
        .subscribe(tags => {
          this.setTagCloudData(tags);
        }, err => {
          this.feedbackService.handleError(err);
        });
  }

  setTagCloudData(occurrences: TagOccurrence[]) {
    const flatOccurrences = occurrences.map(o => o.occurrences).sort();
    const min = flatOccurrences[0];
    const max = flatOccurrences[flatOccurrences.length - 1];
    const hf = max / 2;
    const avg = flatOccurrences.reduce((prev, curr) => prev + curr, 0);
    const qt = max - ((max - avg) / 2);
    const data: CloudData[] = occurrences.map(info => ({
        text: `#${info.tag}`,
        link: `/sessions?tag=${info.tag}`,
        actualOccurrences: info.occurrences,
        weight: info.occurrences * (info.occurrences === min ? 2 : info.occurrences <= avg ? 1.7 : info.occurrences <= (avg + avg / 2) ? 0.2 : 0.1)
    } as any));
    this.data = data;
  }

  ngAfterViewInit() {
    this.onResize();
  }

  @HostListener('document:resize')
  onResize() {
    this.options = {
      ...this.options,
      width: this.elementRef.nativeElement.parentElement.clientWidth
    }
    this.changeDetector.detectChanges();
    if (!this.tagCloud) {
      return;
    }
    this.tagCloud.onResize();
  }
}
