<div class="container">
  <h1>Code of Conduct</h1>

  <p>
    POET Summit welcomes everyone and is dedicated to defending anybody from
    harassment, regardless of gender, gender identity and expression, sexual
    orientation, disability, physical appearance, body size, race, age or
    religion.
  </p>

  <p>
    <strong>We do not tolerate harassment</strong> of conference participants in
    any form. Conference participants violating these rules will be sanctioned
    or expelled from the conference without a refund at the discretion of the
    conference organizers.
  </p>

  <p>
    Harassment includes any verbal comments that reinforce social structure
    domination, sexual images in public spaces, deliberate intimidation,
    stalking or following, sustained disruption of talks or other events,
    inappropriate physical contact or encouraging any of the above behaviours.
    Sexual language and imagery will not be tolerated for any conference venue,
    workshops, parties, talks or online media.
  </p>

  <p>
    <strong
      >Participants asked to stop any harassing behaviour are expected to comply
      immediately.</strong
    >
    Exhibitors, sponsors or exhibitor representatives are also subject to the
    code of conduct. Partners and exhibitors should not use sexualized images,
    activities, or other material. Exhibition staff, conference organizers or
    representatives should not use sexualized clothing, uniforms or costumes or
    otherwise create a sexualized environment.
  </p>

  <p>
    If a participant engages in harassing behaviour, event organizers retain the
    right to take action to maintain a welcoming environment for all
    participants. As organizers, we take the liberty of rejecting offers and
    collaboration with entities that have connections to child labour,
    exploitation of people, gambling, drugs or weapons.
  </p>

  <p>
    We expect participants to follow these rules at all event venues and
    event-related social activities. If you are being harassed, notice that
    someone else is being harassed, or have any other concerns, please contact
    our event coordinators.
  </p>

  <h5>POET Summit Team</h5>

  <p class="pt-0">
    Conference staff will also be happy to help participants contact hotel/venue
    security or local law enforcement, provide escorts, or otherwise assist
    those experiencing harassment to feel safe for the duration of the
    conference.
  </p>
  <p>
    <strong>We value your attendance. </strong>
    <br />
  </p>
</div>
