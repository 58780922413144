import { AfterViewInit, Component, HostListener, isDevMode, OnInit, ViewChild } from "@angular/core";
import { Question } from "../../shared/question.model";
import { QuestionsService } from "../../shared/questions.service";
import { switchMap, take, tap } from "rxjs/operators";
import { config, zip } from "rxjs";
import {
  DialogService,
  DialogAction,
  DialogCloseResult,
  DialogRef,
} from "@progress/kendo-angular-dialog";
import { Router } from "@angular/router";
import { QuizConfigService } from "../../shared/config.service";
import { HelloModalComponent } from '../hello-modal/hello-modal.component';
import { TimerComponent } from "../../shared/timer/timer.component";
import { UserService } from "../../../shared/user.service";
import { FeedbackService } from "../../../shared/feedback.service";

@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.scss"],
})
export class QuestionsComponent implements OnInit, AfterViewInit {
  questions: Question[];
  currentQuestionIndex = -1;
  
  @ViewChild(TimerComponent, { static: false })
  timer: TimerComponent;

  questionTimeout: number;
  quizStarted: boolean;

  currentQuestion: Question;
  emailAlreadyUsed: boolean;

  get allQuestionsAnswered(): boolean {
    if (!this.questions) {
      return false;
    }
    for (const q of this.questions) {
      if (!q.answer) {
        return false;
      }
    }
    return true;
  }

  get currentQuestionAnswered(): boolean {
    return this.currentQuestion && this.currentQuestion.answer !== undefined;
  }

  constructor(
    private questionsService: QuestionsService,
    private router: Router,
    private configService: QuizConfigService,
    private dialogService: DialogService,
    private userService: UserService,
    private feedbackService: FeedbackService
  ) {}

  ngOnInit() {
    console.log("quiz iinit");
    zip(
      this.questionsService.currentQuestionIndex,
      this.questionsService.currentQuestion
    ).subscribe((results) => {
      this.currentQuestion = results[1];
      this.currentQuestionIndex = results[0];
    });

    this.userService.
      getUserProfile()
      .pipe(
        switchMap(profile => this.userService.hasTakenQuiz(profile))
      )
      .subscribe(hasTakenQuiz => {
        this.emailAlreadyUsed = hasTakenQuiz;
        if (hasTakenQuiz) {
          return;
        }
        this.sayHello();
      }, err => {
        this.feedbackService.handleError(err);
      });
      this.userService.signedOut.subscribe(() => this.router.navigate(['/main']), err => {
        this.feedbackService.handleError(err);
      });
  }
  

  ngAfterViewInit() {
    this.questionsService.questions.subscribe(
      (questions) => (this.questions = questions)
    );
    this.questionsService.currentQuestionIndex.subscribe((i) => {
      setTimeout(() => {
        this.currentQuestionIndex = i;
        setTimeout(() => {
          try {
            if (this.currentQuestionIndex === -1) {
              this.timer.stopTimer();
            } else {
              this.timer.initTimer();
            }
          } catch (e) {
            if (isDevMode()) {
              console.warn(e);
            }
          }
        });
      });
    });
  }
    
  sayHello() {
    this.configService
      .getConfig()
      .pipe(
        tap((config: any) => this.questionTimeout = config.Client.QuestionTimeout * 1000),
        switchMap(
          (config) =>
            this.dialogService.open({
              title: "Welcome",
              content: HelloModalComponent,
              actions: [
                {
                  text: "Begin",
                  primary: true
                },
              ],
              preventAction: (ev) => {
                return ev instanceof DialogCloseResult;
              },
            }).result
        )
      )
      .subscribe(() => this.startQuiz(), 
        err => { 
          this.feedbackService.handleError(err);
        });
  }

  startQuiz() {
    console.log("starting quiz");
    this.questionsService
      .loadQuestions()
      .pipe(
        tap((questions) => (this.questions = questions)),
        tap(() => (this.quizStarted = true))
      )
      .subscribe(() => this.questionsService.startQuiz(),
        err => {
          this.feedbackService.handleError(err);
        });
  }

  next() {
    this.questionsService.nextQuestion();
  }

  previous() {
    this.questionsService.previousQuestion();
  }

  submit() {
    if (!this.allQuestionsAnswered) {
      this.dialogService
        .open({
          title: "Confirm",
          content:
            "This will end the quiz. Are you sure you want to submit at this point?",
        actions: [
          {
              text: "NO",
              primary: true,
          },
          {
              text: "YES",
              primary: true,
            },
          ],
        })
        .result.subscribe((result: DialogAction) => {
          if (!result || result.text === "NO") {
          return;
        }
        this.router.navigate(['/quiz/submit']);
      });
    } else {
      this.router.navigate(['/quiz/submit']);
    }
  }

  onTimeLimitReached() {
    this.questionsService.currentQuestion
      .pipe(
        take(1),
        switchMap(
          (question) =>
            this.showTimeoutDialog(!!question.answer && question.answer !== "")
              .result
        )
      )
      .subscribe(() => {
        if (this.currentQuestionIndex === this.questions.length - 1) {
          this.router.navigate(["/submit"]);
        } else {
          this.questionsService.nextQuestion();
        }
      });
  }

  private showTimeoutDialog(answered: boolean): DialogRef {
    let content: string;
    if (answered) {
      content =
        "Time has run out for this question. Your current selected answer will be submitted.";
    } else {
      content = "Unfortunately you did not answer this question in time.";
    }
    if (this.currentQuestionIndex === this.questions.length - 1) {
      content += " Click OK to finish the quiz when you are ready.";
    } else {
      content +=
        " Click OK to move on to the next question when you are ready.";
    }
    return this.dialogService.open({
      title: "Out of Time",
      content,
      actions: [
        {
          text: "OK",
          primary: true,
        },
      ],
      preventAction: (ev) => {
        return ev instanceof DialogCloseResult;
      },
    });
  }
  
  @HostListener("window:beforeunload", ["$event"])
  canLeavePage(event: any) {
    if (this.currentQuestionIndex >= 0) {
      event.returnValue = true;
    }
  }
}
