<iframe
  [src]="formSourceSafeUrl"
  style="border: 0px #f7f7f9 none; background-color: #f7f7f9"
  name="myiFrame"
  scrolling="no"
  frameborder="0"
  marginheight="0px"
  marginwidth="0px"
  height="680px"
  width="100%"
  allowfullscreen
></iframe>
