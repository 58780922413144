<p class="text-left session-title section-text pb-0">
    <span class="mr-2 session-star" *ngIf="isAuthenticated" (click)="toggleFavorite.next(!session?.Favorite_Session)">
        <em [class]="'fa fa-star' + (session.Favorite_Session ? '' : '-o')"></em>
    </span>
    <strong>{{session.Session_Title}}</strong>
</p>
<p class="speaker-name section-text pt-0">
    <span *ngFor="let speaker of session.Session_Speakers let i = index">
        <a [routerLink]="'/sessions'" [queryParams]="{ speaker: speaker.SpeakerID }">
            {{speaker.Name + ','}}
        </a>
    </span>
    <span *ngFor="let speaker of session.Session_Speakers let i = index">
        <ng-container *ngIf="speaker.Company">
            {{speaker.Company.trim() + (i === session.Session_Speakers.length - 1 ? ',' : '')}}
            <ng-container *ngIf="i < session.Session_Speakers.length - 1">
                /
            </ng-container>
        </ng-container>
    </span>{{session.Session_Duration}} minutes
</p>
<p class="session-time section-text pt-0">
    {{session.date}}, {{session.time}} {{session.timezoneDisplay}}
</p>
<p class="section-text">
    <a class="track-link" routerLink="/sessions" [queryParams]="{ track: session.Track }">{{session.Track}}</a>
</p>
<p class="session-description section-text">
    <ng-container *ngIf="session.Missing_Session_Description">
        TBD
    </ng-container>
    <ng-container *ngIf="!session.Missing_Session_Description">
        <span [innerHTML]="session.Session_Description | HyperlinkConvert | SessionDescription"></span>
    </ng-container>
</p>
<div class="section-text d-flex flex-wrap">
    <div class="mr-auto my-2 my-xl-0 align-self-flex-start" *ngIf="isAuthenticated && session.Session_ID && session.EventID && session.StreamSecret">
        <a [routerLink]="['/stream']" [queryParams]="{ sessionId: session.Session_ID, eventID: session.EventID, streamID: session.StreamSecret }">
            View Presentation
        </a>
    </div>
    <div class="ml-0 mr-auto d-flex flex-wrap align-self-center">
        <div class="mr-2 my-2 my-xl-0 align-self-center" *ngFor="let tag of session.Session_Tags">
            <a class="session-tag py-1 px-2 my-2 my-xl-0" routerLink="/sessions" [queryParams]="{ tag: tag }">
                #{{tag}}
            </a>
        </div>
    </div>
</div>