import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { tap, take } from "rxjs/operators";
import { UserService } from "./user.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (route.routeConfig.path !== "profile") {
      return of(true);
    }
    return this.userService.authenticated.pipe(
      take(1),
      tap((authenticated) => {
        if (authenticated) {
          return;
        }
        this.router.navigate(["main"]);
      })
    );
  }
}
