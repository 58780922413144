<div id="invoice-dialog">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            <span class="k-icon k-i-form-element"></span>
            Invoice Information
        </div>
    </kendo-dialog-titlebar>
    <div class="container">
        <form name="order-form" #form="ngForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <kendo-textbox-container floatingLabel="First name" class="required">
                            <input kendoTextBox class="form-control" [(ngModel)]="invoice.FirstName" name="FirstName" required />
                        </kendo-textbox-container>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <kendo-textbox-container floatingLabel="Last name" class="required">
                            <input kendoTextBox class="form-control" [(ngModel)]="invoice.LastName" name="LastName" required />
                        </kendo-textbox-container>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <kendo-textbox-container floatingLabel="E-mail" class="required">
                    <input kendoTextBox class="form-control" [(ngModel)]="invoice.Email" name="Email" required />
                </kendo-textbox-container>
            </div>
            <div class="form-group">
                <kendo-textbox-container floatingLabel="Company name" class="required">
                    <input kendoTextBox class="form-control" [(ngModel)]="invoice.CompanyName" name="CompanyName" required />
                </kendo-textbox-container>
            </div>
            <div class="form-group">
                <kendo-textbox-container floatingLabel="Phone">
                    <input kendoTextBox class="form-control" [(ngModel)]="invoice.Phone" name="Phone" />
                </kendo-textbox-container>
            </div>
            <div class="form-group">
                <kendo-textbox-container floatingLabel="Address 1" class="required">
                    <input kendoTextBox class="form-control" [(ngModel)]="invoice.Address1" name="Address1" required />
                </kendo-textbox-container>
            </div>
            <div class="form-group">
                <kendo-textbox-container floatingLabel="Address 2">
                    <input kendoTextBox class="form-control" [(ngModel)]="invoice.Address2" name="Address2" />
                </kendo-textbox-container>
            </div>
            <div class="form-group">
                <kendo-textbox-container floatingLabel="Address 3">
                    <input kendoTextBox class="form-control" [(ngModel)]="invoice.Address3" name="Address3" />
                </kendo-textbox-container>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <kendo-textbox-container floatingLabel="Country" class="required">
                            <kendo-combobox [data]="countries" [(ngModel)]="invoice.Country" name="Country" required>
                            </kendo-combobox>
                        </kendo-textbox-container>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <kendo-textbox-container floatingLabel="City" class="required">
                            <input kendoTextBox class="form-control" [(ngModel)]="invoice.City" name="City" required />
                        </kendo-textbox-container>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <kendo-textbox-container floatingLabel="Post code">
                            <input kendoTextBox class="form-control" [(ngModel)]="invoice.PostCode" name="PostCode" />
                        </kendo-textbox-container>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="onCancelAction()">Cancel</button>
        <button kendoButton (click)="onConfirmAction()" primary="true" [disabled]="!form.valid">Submit</button>
    </kendo-dialog-actions>

</div>