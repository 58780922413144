import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegisterRoutingModule } from './register-routing.module';
import { RegisterComponent } from './register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { RegistrationService } from './registration.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { SharedModule } from '../shared/shared.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
  declarations: [RegisterComponent],
  exports: [RegisterComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    LayoutModule,
    ButtonsModule,
    DropDownsModule,
    IndicatorsModule,
    SharedModule,
    TooltipModule
  ],
  providers: [
    RegistrationService
  ]
})
export class RegisterModule { }
