import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { zip } from "rxjs";
import { switchMap, take, tap } from "rxjs/operators";
import { Session } from "../model/session.model";
import { FeedbackService } from "../shared/feedback.service";
import { UserService } from "../shared/user.service";
import { SessionsByTrack, SessionsService } from "./sessions.service";

@Component({
  selector: "app-sessions",
  templateUrl: "./sessions.component.html",
  styleUrls: ["./sessions.component.scss"],
})
export class SessionsComponent implements OnInit {
  sessions: SessionsByTrack;
  trackNames: string[];

  trackNamesEscaped: { [trackName: string]: string };

  isAuthenticated: boolean;

  @Output()
  onReady: EventEmitter<void> = new EventEmitter();

  constructor(
    private sessionsService: SessionsService,
    private userService: UserService,
    private feedbackService: FeedbackService
  ) {}

  ngOnInit() {
    this.userService.authenticated
      .pipe(
        take(1),
        tap((authenticated) => (this.isAuthenticated = authenticated))
      )
      .subscribe(() => {
        this.refreshSessions();
      });
    this.userService.authenticated.subscribe(authenticated => {
      this.isAuthenticated = authenticated;
      this.refreshSessions();
    });
  }

  refreshSessions() {
    this.sessionsService
      .getSessionsByTracks(this.isAuthenticated)
      .subscribe((sessions) => {
        this.sessions = sessions;
        this.trackNames = Object.keys(sessions).sort();
        this.trackNamesEscaped = {};
        for (const trackName of this.trackNames) {
          this.trackNamesEscaped[trackName] = trackName.replace(/\ /g, "_");
        }
        setTimeout(() => {
          this.onReady.next();
        });
      }, err => {
        this.feedbackService.handleError(err);
      });
  }

  toggleSessionFavorite(session: Session, isFavorite: boolean) {
    this.sessionsService
      .markSessionAsFavorite(session, isFavorite)
      .subscribe(() => (session.Favorite_Session = isFavorite),
        err => {
          this.feedbackService.handleError(err);
        });
  }
}
