import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Session } from '../../model/session.model';
import { UserService } from '../../shared/user.service';
import { ISchedule, Timeslot } from '../schedule.model';
import { ScheduleService } from '../schedule.service';

@Component({
  selector: 'day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['../schedule.component.scss', './day-view.component.scss']
})
export class DayViewComponent implements OnInit {

  @Input()
  timeslots: Timeslot[];

  @Input()
  day: number;

  @Input()
  rooms: string[];

  @Output()
  toggleFavorite: EventEmitter<{ session: Session, isFavorite: boolean }> = new EventEmitter();

  selectedRoom = 0;

  authenticated: boolean;
  isMobileDevice: boolean;

  constructor(private userService: UserService, private scheduleService: ScheduleService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.userService.authenticated.subscribe(auth => {
      this.authenticated = auth;
    });

    this.scheduleService.isMobileDevice.subscribe((val) => {
      this.isMobileDevice = val;
      this.changeDetector.detectChanges();
    })
  }

}
