<div class="container">
    <div id="q-wrap" *ngIf="quizStarted">
        <div style="height: auto; flex: 100;" class="pt-3 px-4">
            <app-question-viewer [currentQuestion]="currentQuestion" *ngIf="quizStarted"></app-question-viewer>
        </div>

        <div class="navigator-container">
            <div class="navigator d-flex">
                <ng-container>
                    <button
                        [disabled]="currentQuestionIndex >= questions.length - 1 || !currentQuestion.answer"
                        (click)="next()"
                        class="k-button h-100 mr-5 font-weight-bold">
                            NEXT
                    </button>
                    <button
                        (click)="submit()"
                        class="k-button h-100 ml-5 mr-auto font-weight-bold">
                            FINISH
                    </button>
                    <span class="navbar-text font-weight-bold text-cw-gray ml-auto" style="align-self: center;" *ngIf="currentQuestionIndex > -1">
                        <p>Question #{{currentQuestionIndex + 1}} of {{questions?.length}}</p>
                          <app-timer [limit]="questionTimeout"  (timeOver)="onTimeLimitReached()"></app-timer>
                    </span>
                </ng-container>
            </div>
        </div>
    </div>
    <div id="email-used" *ngIf="emailAlreadyUsed">
        <h3 class="mt-3 px-2 text-cw-gray">We thank you for your interest, however each delegate may only participate in the Trivia Quiz once.</h3>
    </div>
</div>