<div class="container">
  <h1>Imprint</h1>

  <p>
    The <strong>POET Summit</strong> is organized for the OpenEdge community by Consultingwerk
    and it's partners.
  </p>

  <p class="w-100 text-left" id="logo">
    <img src="/assets/images/sponsors/cwlogo_small.png">
  </p>

  <p class="pb-0 mb-0"><strong>Consultingwerk Software Services Ltd.</strong></p>
  <p class="pt-0 mt-0">
    Schanzenstraße 31<br />
    51063 Köln<br />
    Germany
  </p>
  <p class="mt-1 pt-1">
    Telefon: +49 (0)221 / 677 88 55-0<br />
    Telefax: +49 (0)221 / 677 88 55-5
  </p>
  <p class="mt-1 pt-1">
    Registered with the Companies House for England and Wales: Company No.
    07895735<br />
    VAT-ID (Germany): DE304115343 <br />VAT-Registration Number (Switzerland):
    CHE-369.459.522 MWST
  </p>
  <p class="mt-1 pt-0 mb-2">
    <a href="https://www.consultingwerk.com">https://www.consultingwerk.com</a>
  </p>
</div>
