import { Component, OnInit } from '@angular/core';
import { setCollapsibleParagraph } from '../utils';

@Component({
  selector: 'speakers',
  templateUrl: './speakers.component.html',
  styleUrls: ['./speakers.component.scss']
})
export class SpeakersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    setCollapsibleParagraph('mike');
    setCollapsibleParagraph('coenie');
    setCollapsibleParagraph('julian-ls');
    setCollapsibleParagraph('daniel');
    setCollapsibleParagraph('thomas');
  }

}
