<!-- Adam Backman -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/adam.jpeg" class="card-img" alt="adam_backman" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Adam Backman</h4>
        <h5 class="card-title">DBA Extraordinaire, White Star Software</h5>

        <p class="card-text">
          Adam is White Star Software's resident expert on database and system planning,
          tuning and administration. He has a particular gift for understanding how
          infrastructure interacts and affects OpenEdge systems.
        </p>
        <p class="card-text">
          Adam brings over 30 years of OpenEdge knowledge and experience to each of his client's
          business issues, and uses a logical, business-centric approach in his work.
        </p>

        <a href="mailto:adam@wss.com">adam@wss.com</a>
        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'adambackman' }">
            View sessions by Adam Backman
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />
<!-- Tom Bascom -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/tom_bascom.jpg" class="card-img" alt="tom_bascom" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Tom Bascom</h4>
        <h5 class="card-title">Curmudgeon, White Star Software</h5>

        <p class="card-text">
          Tom has been working with Progress since 1987 and is well-known to the
          Progess community for his frequent postings in online forums reminding
          people that version 9 is ancient, obsolete and unsupported and that
          OE10 will be following soon. He also occasionally posts helpful
          information on getting the most out of OpenEdge - even if you are
          still running v9 (if you are still running v9 you should, of course,
          upgrade).
        </p>
        <p class="card-text">
          When he is not taking people to task for running dangerously obsolete
          releases or complaining about paperwork, Tom is either working on
          developing ProTop, the Best OpenEdge DB Monitoring tool in the Galaxy,
          or consulting with customers throughout the world helping improve
          their OpenEdge system's performance and scalability. Or he might be
          walking the dog. Walking the dog is very relaxing. Tom also has a
          fondness for a good dark beer and has been known to bite his tongue
          regarding the wisdom of continuing to run v9 if a nice cold porter or
          stout is in hand.
        </p>

        <span><a href="mailto:tom@wss.com">tom@wss.com</a></span>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'" [queryParams]="{ speaker: 'tombascom' }">
            View sessions by Tom Bascom
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Andrei Cornei -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/andrei.jpg" class="card-img" alt="Andrei Cornei" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Andrei Cornei</h4>
        <h5 class="card-title">Tech Lead, Wayfare</h5>

        <p class="card-text">
          Andrei is a Tech Lead at Wayfare with over 15 years of experience
          in the Progress world. His work is not just about creating,
          but also about passing on the knowledge and the experience that he has.
          The main recipients of his knowledge are the OpenEdge trainees that
          Wayfare had over the past years, or as he calls them, “Kinders”.
          He sees himself as not just the teacher, but also as the student
          because from every challenge he gets, he learns something new.
        </p>
        <span><a href="mailto:andrei.cornei@wayfare.ro">andrei.cornei@wayfare.ro</a></span>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'andreicornei' }">
            View sessions by Andrei Cornei
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Dave Darby -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/dave_darby.jpg" class="card-img" alt="Dave Darby" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Dave Darby</h4>
        <h5 class="card-title">Senior Consultant, Cyberscience Corporation</h5>

        <p class="card-text">
          With over 30 years industry experience as a programmer, analyst, designer and implementer of business systems
          and Business Intelligence. Dave has implemented B.I. solutions for Progress customers since Progress version
          7. He is part of the team that provides feedback for our developers to refine and improve our product set.
        </p>

        <p class="card-text">
          Dave currently provides training and consulting for all of our customers’ varied requirements and has
          experience with a wide variety of relational and non-relational databases, operating systems and
          methodologies.
        </p>
        <span><a href="mailto:add@cyberscience.com">add@cyberscience.com</a></span>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'" [queryParams]="{ speaker: 'davedarby' }">
            View sessions by Dave Darby
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Daniel van Doorn -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/daniel.jpg" class="card-img" alt="daniel" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Daniël van Doorn</h4>
        <h5 class="card-title">Senior Consultant, Consultingwerk</h5>

        <p class="card-text">
          Daniël is a senior consultant at Consultingwerk, who has experience
          with OpenEdge since 1997 when he started with version 7. His interests
          have always been the challenges with a more technical focus with a
          sharp eye for new technologies and the chances those can offer.
        </p>
        <p class="card-text">
          Since 1997 Daniël has worked in different roles varying from
          Development to Operations. Therefor he is able to act while focussing
          both on the needs of the developers as on the needs of the Operations.
        </p>

        <div class="daniel-collapsible collapse">
          <p class="card-text">
            The last decade, his primary area of interest has become DevOps.
            Assuring a streamlined process for development, build, delivery and
            deployment of OpenEdge related software and implementing feedback
            loops for pro-active problem solving and making sure every delivery
            is transparent and results in provable/traceable software.
          </p>
          <p class="card-text">
            Currently Daniël fulfils the role of PO DevOps in a DevOps team on
            one of our largest projects.
          </p>
        </div>
        <button id="daniel-more" class="btn btn-link">Show more</button>
        <button id="daniel-less" class="btn btn-link">Show less</button>
        <br />
        <a href="mailto:daniel.van.doorn@consultingwerk.com">daniel.van.doorn@consultingwerk.com</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'danielvandoorn' }">
            View sessions by Daniël van Doorn
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Marian Edu -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/marian.jpg" class="card-img" alt="Marian Edu" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Marian Edu</h4>
        <h5 class="card-title">Owner / ACORN IT</h5>

        <p class="card-text">
          Marian is working in the IT industry for more than 20 years in areas
          ranging from system administration, business application development,
          database design, application and solution architecture to business
          intelligence and enterprise data warehouse implementation.
          Owner of ACORN IT, currently mixing product development and software
          development with a strong focus on OpenEdge, Java and node.js technologies.
        </p>
        <p class="card-text">
          While continuing to help customers with application/system integration,
          micro-services implementation and bespoke software development and consulting
          Marian is constantly working on bringing new functionalities into
          Zamolxis - and Entity Relationship Diagram designer tailored for OpenEdge RDBMS.
        </p>
        <a href="mailto:marian.edu@acorn.ro">marian.edu@acorn.ro</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'" [queryParams]="{ speaker: 'marianedu' }">
            View sessions by Marian Edu
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- George Ene -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/george_ene.jpg" class="card-img" alt="George Ene" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">George Ene</h4>
        <h5 class="card-title">Tech Lead, Wayfare</h5>

        <p class="card-text">
          George is a Tech Lead at Wayfare with over 14 years of experience
          in working with Progress OpenEdge applications. He is enthusiastic
          about JavaScript frameworks since he began his journey as an Angular
          developer 4 years ago, and nowadays, he builds modern web or mobile
          front ends for OpenEdge applications. His good knowledge of the entire
          software development life cycle and the ability to organize international
          distributed teams enables him to easier transform each idea into a great product.
        </p>
        <a href="mailto:george.ene@wayfare.ro">george.ene@wayfare.ro</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'" [queryParams]="{ speaker: 'georgeene' }">
            View sessions by George Ene
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr>


<!-- Klaus Erichsen -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/klaus_erichsen.png" class="card-img" alt="Klaus Erichsen" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Klaus Erichsen</h4>
        <h5 class="card-title">Managing Director / System Architekt, IAP</h5>

        <p class="card-text">
          Klaus studied electro technique until 1989, then immediately started
          working as software developer with 4GL. Over the years he co founded IAP
          in 1992, tuned some big databases, created several applications and
          frameworks together with his colleagues. Today he works in the areas of
          technical consulting, system design, DB tuning and marketing. Web
          technologies and web trends are always in his scope of attention. He is
          a speaker in the OpenEdge community.
        </p>
        <a href="mailto:ke@iap.de">ke@iap.de</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'klauserichsen' }">
            View sessions by Klaus Erichsen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Lutz Fechner -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/lutz_fechner.JPG" class="card-img" alt="lutz_fechner" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Lutz Fechner</h4>
        <h5 class="card-title">Project Consultant / Delivery Manager, Consultingwerk</h5>

        <p class="card-text">
          He worked before as a Team Lead and Software Architect in the B2B ePro curement Industry mostly in Java and
          .NET Software-Projects.
        </p>
        <p class="card-text">
          Next to his role as a Delivery Manager he also takes over various software development tasks in different
          technologies (Java, .NET, ABL, C).
        </p>
        <br />
        <a href="mailto:lutz.fechner@consultingwerk.de">lutz.fechner@consultingwerk.de</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'lutzfechner' }">
            View sessions by Lutz Fechner
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />


<!-- Mike Fechner -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/mike.jpg" class="card-img" alt="mike_fechner" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Mike Fechner</h4>
        <h5 class="card-title">Director, Consultingwerk</h5>

        <p class="card-text">
          Mike Fechner, director and lead modernization architect at
          Consultingwerk started using Progress over 30 years ago and ever since
          has supported Progress Application Partners and end customers in
          adopting the features of the latest OpenEdge and Progress releases to
          enhance the capabilities of existing applications.
        </p>
        <p class="card-text">
          With his framework design skills he has set the stage for development
          of many successful OpenEdge applications.
        </p>
        <p class="card-text">
          Mike is specialized on object orientation in the ABL, software
          architectures, the GUI for .NET, web technologies and a wide range of
          Progress products such as OpenEdge, Telerik and Corticon.
        </p>
        <div class="mike-collapsible collapse">
          <p class="card-text">
            He is involved in software modernization projects on a day by day
            basis. He is a well-known and active member of the international
            OpenEdge community, frequent presenter at conferences around the
            world and is a board member of the German PUG and founder of the
            committee of the EMEA PUG Challenge. He’s also a founding member of
            the Common Component Specification project.
          </p>
        </div>
        <button id="mike-more" class="btn btn-link">Show more</button>
        <button id="mike-less" class="btn btn-link">Show less</button>
        <br />
        <a href="mailto:mike.fechner@consultingwerk.de">mike.fechner@consultingwerk.de</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'mikefechner' }">
            View sessions by Mike Fechner
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Nick Finch -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/nick.jpg" class="card-img" alt="nick_finch" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Nick Finch</h4>
        <h5 class="card-title">CTO, DataPA</h5>

        <p class="card-text">
          Nick has been working in the Progress community since the mid 90’s.
          As co-founder and CTO of DataPA, Nick was instrumental in the development
          of DataPA OpenAnalytics, which delivered analytics and reporting to over 300
          businesses running Progress applications around the world.
        </p>
        <p class="card-text">
          In July 2018, DataPA became inmydata, launching a new AI driven, cloud analytics platform.
          The company continues to support OpenEdge customers with analytics,
          offering native AppServer connecters and embedded solutions for OpenEdge
          that use the latest cloud, AI and web assembly technologies.
        </p>
        <p class="card-text">
          Nick has a long history of helping customers leverage their OpenEdge investment with other modern
          technologies.
        </p>
        <a href="mailto:nfinch@datapa.com">nfinch@datapa.com</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'" [queryParams]="{ speaker: 'nickfinch' }">
            View sessions by Nick Finch
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Edsel Garcia -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/edsel_garcia.jpeg" class="card-img" alt="edsel_garcia" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Edsel Garcia</h4>
        <h5 class="card-title">Software Architect, Progress Software</h5>

        <p class="card-text">
          Edsel Garcia is a Software Architect in the Progress OpenEdge Engineering group. Edsel has a long history of
          first-hand experience using Progress products, starting as a customer and application developer more than 25
          years ago.
        </p>
        <p class="card-text">
          During his over 20-year tenure at Progress, Edsel has been a member of many teams, including Customer Support,
          Solution Engineering development, Tooling development, OpenEdge Architect product development and more.
        </p>
        <p class="card-text">
          Edsel has recently been working on the OpenEdge on AWS Quick Start project and in new functionality for
          WhatsUp Gold.
        </p>
        <a href="mailto:egarcia@progress.com">egarcia@progress.com</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'edselgarcia' }">
            View sessions by Edsel Garcia
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />


<!-- Wilhelmus van der Ham -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/wim.jpg" class="card-img" alt="Wilhelmus van der Ham (Wim)" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Wilhelmus van der Ham (Wim)</h4>
        <h5 class="card-title">Director, Worldwide IT Solutions</h5>

        <p class="card-text">
          Wilhelmus van der Ham (Wim) was born in the Netherlands but is living and working in Italy for 29 years.
          In fact, he has lived longer in Italy than in the Netherlands. While Progress is celebrating its 40th
          birthday,
          Wim celebrated 34 years of Progress experience that started with the DLC purple Progress Test Drive box in
          1987.
          He’s director of his own small company WITS – Worldwide IT Solutions which offers Progress Consultancy to
          Progress partners and customers in Italy,
          in particular Sintesi Software and Ormar SNC.
        </p>
        <a href="mailto:wim@wits.it">wim@wits.it</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'wimvanderham' }">
            View sessions by Wim van der Ham
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Thomas Hansen -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/thomas.jpg" class="card-img" alt="thomas_hensen" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Thomas Hansen</h4>
        <h5 class="card-title">appSolutions</h5>

        <p class="card-text">
          Thomas has been working with OpenEdge and Application Lifecycle
          Management since 1995. A particular focus has been on Roundtable TSMS,
          version control systems and all aspects of application development
          through deployment. In recent years this has been updated with a
          passion for modern day DevOps and automation, Docker and containers
          and related technologies. This knowledge has been used on several
          projects over the years to help customers containerized their OpenEdge
          applications, while integrating them with other smart technologies.
        </p>
        <p class="card-text">
          He has helped develop Roundtable Automation, a product that extends
          Roundtable implementations and opens them up to the world of DevOps.
          This has been created with the combination of OpenEdge, Roundtable
          TSMS, Docker, Jenkins and other tools like Secureable (identity and
          secrets management for PASOE and other OpenEdge tools).
        </p>
        <div class="thomas-collapsible collapse">
          <p class="card-text">
            Thomas is also involved in arranging and participating in Progress
            related conferences all over the world. This is part of a strong
            interest in the “knowledge business” - sharing knowledge and
            teaching others about things that drive and excites him. Other
            interests include personal and business development, mountain
            biking, healthy living and eating and exercising.
          </p>
        </div>

        <button id="thomas-more" class="btn btn-link">Show more</button>
        <button id="thomas-less" class="btn btn-link">Show less</button>
        <br />
        <a href="mailto:thomas@app-solutions.com">thomas@app-solutions.com</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'thomashansen' }">
            View sessions by Thomas Hansen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Peter Judge -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/the_judge.png" class="card-img" alt="Peter Judge" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Peter Judge</h4>
        <h5 class="card-title">Software Architect, Progress Software</h5>

        <p class="card-text">
          Peter Judge is a software architect in the OpenEdge group at Progress,
          where he mainly works on integration and server technologies such as the
          PASOE server and HTTP client. Most recently, Peter has been helping a
          variety of customers with their move to PAS for OpenEdge in OpenEdge Release 12.
        </p>
        <p class="card-text">
          Peter’s background is in application and toolkit design and development,
          but his interests span the whole SDLC from design through to deployment,
          regardless of language and technology, and is a regular speaker at PUG
          and other Progress events on a range of topics from application design
          and programming REST APIs, to general ABL topics like performance and debugging.
          On most days he can be found on the Progress Communities and other discussion forums.
        </p>
        <p class="card-text">
          Peter likes to make things work together, and learned nearly everything
          he’s needed to know about software development from playing with Lego, mainly as a child.
        </p>
        <a href="mailto:pjudge@progress.com">pjudge@progress.com</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'peterjudge' }">
            View sessions by Peter Judge
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Paul Koufalis -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/paul.jpg" class="card-img" alt="paul_koufalis" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Paul Koufalis</h4>
        <h5 class="card-title">President, White Star Software</h5>

        <p class="card-text">
          Paul began his OpenEdge DBA career over 25 years ago after completing a
          Computer Engineering Degree at McGill University in Montreal.
        </p>
        <p class="card-text">
          Today, he leads a growing team at White Star Software,
          delivering best-in-class monitoring, support and DBA consulting services to a global client base.
        </p>
        <a href="mailto:pk@wss.com">pk@wss.com</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'paulkoufalis' }">
            View sessions by Paul Koufalis
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Jeff Ledbetter -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/jeff.jpg" class="card-img" alt="jeff_ledbetter" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Jeff Ledbetter</h4>
        <h5 class="card-title">Product Architect, Roundtable Software</h5>

        <p class="card-text">
          Jeff Ledbetter is owner and Product Architect at Roundtable Software, a Progress Technology Alliance partner.
          He is responsible for defining technical strategy and managing development for the Roundtable family of
          software products.
        </p>
        <p class="card-text">
          Jeff has been a member of the Progress community since 1995.
        </p>
        <a href="mailto:jeff.ledbetter@roundtable-software.com">jeff.ledbetter@roundtable-software.com</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'jeffledbetter' }">
            View sessions by Jeff Ledbetter
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Mike Liewehr -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/mike_liewehr.jpg" class="card-img" alt="mike_liewehr" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Mike Liewehr</h4>
        <h5 class="card-title">Build.One</h5>

        <p class="card-text">
          Mike Liewehr is founder and CEO of Build.One (former AKIOMA Software GmbH), provider of a low-code/no-code
          development platform for complex business applications, a unique combination of an OpenEdge backend, the
          Corticon rules engine, node.js and a modern Javascript / HTML5 web frontend.
        </p>
        <p class="card-text">
          Mike has over 25 years of experience in the Progress community and regularly speaks at conferences worldwide.
          He has led several large modernization projects and is passionate about complex software and system
          architectures, user experience design, and improving business processes through innovative IT solutions.
        </p>
        <a href="mailto:mike.liewehr@build.one">mike.liewehr@build.one</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'mikeliewehr' }">
            View sessions by Mike Liewehr
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />



<!-- Julian Lyndon-Smith -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/julian_lyndon_smith.png" class="card-img" alt="julian_lyndon_smith" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Julian Lyndon-Smith</h4>
        <h5 class="card-title">
          Director, Nodeable: Specialist consultants in OpenEdge, Docker and
          node JS
        </h5>

        <p class="card-text">
          Julian has presented at a number of conferences over the past 25 years
          on a wide and varied number of topics.
        </p>
        <p class="card-text">
          With over 33 years experience in Progress, and with his skills in all
          versions of Progress from v3 to v12, OOABL , database design and
          PASOE, Julian is actually in some danger of knowing what he's talking
          about!
        </p>
        <p class="card-text">
          He has written a number of systems in Progress, including leading edge
          call center software that was completely integrated with VoIP
          technology using RabbitMQ, and Secureable - an identity and secrets
          management system for PASOE and OpenEdge.
        </p>

        <div class="julian-ls-collapsible collapse">
          <p class="card-text">
            Julian was an early adopter of Docker in 2014, with Nodeable being
            one of the first companies to put Progress into Docker containers.
          </p>
          <p class="card-text">
            He also begrudgingly uses Flutter, Angular 12, Typescript, Node,
            NestJS and TypeOrm - linking modern frontend and backend
            technologies with the stability and functionality of PASOE and the
            OpenEdge database.
          </p>
        </div>
        <button id="julian-ls-more" class="btn btn-link">Show more</button>
        <button id="julian-ls-less" class="btn btn-link">Show less</button>
        <br />
        <a href="mailto:julian@nodeable.io">julian@nodeable.io</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'julianlyndonsmith' }">
            View sessions by Julian Lyndon-Smith
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Steffen Maas -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/steffen_maas.jpg" class="card-img" alt="Steffen Maas" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Steffen Maas</h4>
        <h5 class="card-title">
          Managing Director, Ginkgo Analytics
        </h5>

        <p class="card-text">
          Steffen Maas is the founder and managing director of Ginkgo Analytics,
          a spin-off of a management consultancy specializing in data science.
          Ginkgo Analytics' mission is to transform companies into data-driven and AI-powered companies.
          The AI experts at Ginkgo Analytics implement prototypes and productive solutions based on open
          source and commercial solutions, and advise and train companies in the use of AI.
          As a member of the KI Bundesverband and as a keynote speaker and mentor,
          Steffen is happy to pass on his knowledge of AI to companies, science and society.
        </p>
        <a href="mailto:steffen.maas@ginkgo-analytics.com">steffen.maas@ginkgo-analytics.com</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'steffenmaas' }">
            View sessions by Steffen Maas
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Radu Nicoara -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/radu.jpeg" class="card-img" alt="radu_nicoara" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Radu Nicoara</h4>
        <h5 class="card-title">Consultant/Developer, Consultingwerk</h5>

        <p class="card-text">
          Radu Nicoara has been using OpenEdge for about 5 years. He has several
          years of experience in Angular, NativeScript and node.js.
        </p>
        <p class="card-text">
          In his role as a Developer with Consultingwerk, he developed the
          SmartComponent Library for Angular and NativeScript.
        </p>
        <p class="card-text">
          As a Consultant, he supports customers, offers consultation for web
          and mobile architecture and provides training and mentoring services.
        </p>
        <a href="mailto:radu.nicoara@consultingwerk.com">radu.nicoara@consultingwerk.com</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'radunicoara' }">
            View sessions by Radu Nicoara
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />
<!-- Bronco Oostermeyer -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/bronco.jpg" class="card-img" alt="bronco_oostermeyer" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Bronco Oostermeyer</h4>
        <h5 class="card-title">Consultant, Flusso</h5>

        <p class="card-text">
          Bronco Oostermeyer has boarded the Progress OpenEdge community 25
          years ago starting with 8.0A. Having worked for various companies,
          among which Progress, focus was always to be on the forefront of what
          OpenEdge has to offer.
        </p>
        <p class="card-text">
          In recent years this focus shifted more to the edges of the OpenEdge
          environment. Docker, Kubernetes, CI/CD, IAM and recently Go are tricks
          upon his sleeve, believing that a modern environment is best served
          with best of breed solutions where OpenEdge with the 4GL, PAS and the
          database are strong foundations for creating business applications.
        </p>
        <p class="card-text">
          Bronco is a strong believer in open source software as well as sharing
          as much knowledge as possible via means like github, presentations and
          the community in general.
        </p>

        <span><a href="mailto:b.oostermeyer@flusso.nl">b.oostermeyer@flusso.nl</a></span>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'broncoostermeyer' }">
            View sessions by Bronco Oostermeyer
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />
<!-- Patrice Perrot -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/patrice_perrot.jpg" class="card-img" alt="patrice_perrot" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Patrice Perrot</h4>
        <h5 class="card-title">Proginov</h5>

        <p class="card-text">
          I began my OpenEdge career 20 years ago as developer.
        </p>
        <p class="card-text">I have optimized Progress source since 2005.</p>
        <p class="card-text">I am a DBA at Proginov since 2010.</p>
        <p class="card-text">
          Dealing with more than 12 000 DB in production, I have to optimize DB,
          source code and make tool to do.
        </p>
        <a href="mailto:pperrot@proginov.com">pperrot@proginov.com</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'patriceperrot' }">
            View sessions by Patrice Perrot
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Justas Poliakas -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/justas.jpg" class="card-img" alt="Justas Poliakas" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Justas Poliakas</h4>
        <h5 class="card-title">Baltic Amadeus</h5>

        <p class="card-text">
          Justas Poliakas started to work with Progress OpenEdge only 5 years ago.
          Right from the beginning wanted to breathe in some new winds into the
          Progress community. Justas is passionate about CI/CD, tech-related
          thinkering and making everyday life better by creating utilities and automations.
          Justas invites you to join him for the introduction to OpenTracing and a demo of utility at work.
        </p>
        <a href="mailto:J.Poliakas@ba.lt">J.Poliakas@ba.lt</a>
        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'justaspoliakas' }">
            View sessions by Justas Poliakas
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr>

<!-- Gilles Querret -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/gilles.jpg" class="card-img" alt="gilles_querret" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Gilles Querret</h4>
        <h5 class="card-title">Owner, Riverside Software</h5>

        <p class="card-text">
          Gilles is the owner of Riverside Software, a software development shop
          focused on OpenEdge and Java technologies. During his career as a
          contractor and product manager, Gilles always tried to improve the
          tools and procedures used by development teams to help them deliver
          early and effectively.
        </p>
        <p class="card-text">
          Gilles now works on code analysis, and helps customers implement
          continous integration as well as continuous code inspection.
        </p>
        <a href="mailto:g.querret@riverside-software.fr">g.querret@riverside-software.fr</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'gillesquerret' }">
            View sessions by Gilles Querret
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr>

<!-- Rob Straight -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/rob_straight.jpg" class="card-img" alt="rob_straight" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Rob Straight</h4>
        <h5 class="card-title">Director, OpenEdge Product Management</h5>

        <p class="card-text">
          Rob has been part of the OpenEdge Product Management team since 2005, gathering user requirements, setting
          strategy, and organizing product releases to meet the goals of the OpenEdge community and of Progress.
        </p>
        <p class="card-text">
          He built applications at the start of his career, and from there has had a focus on software development
          tools, processes, and quality for most of his time working in Product Management.
        </p>
        <p class="card-text">
          He encourages user feedback via Idea submissions and the Customer Validation Program (CVP), which both help
          Progress to shape new capabilities for all OpenEdge users.
        </p>
        <a href="mailto:rstraigh@progress.com">rstraigh@progress.com</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'robstraight' }">
            View sessions by Rob Straight
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr>

<!-- Patrick Tingen -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/patrick.jpg" class="card-img" alt="Patrick Tingen" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Patrick Tingen</h4>
        <h5 class="card-title">Developer, Axians</h5>

        <p class="card-text">
          Patrick Tingen is a senior developer, working for Axians in Groningen, the Netherlands.
          He started working with Progress v6 in 1993 in a Novell Netware environment.
          He likes building tools, clean code and good user interfaces and is a firm believer in open source code.
        </p>
        <p class="card-text">
          Since 2008, he is the proud maintainer of the DataDigger, a free (as in free beer) open source tool, mainly
          focused on developers.
        </p>
        <p class="card-text">
          When not busy with programming-related stuff, you can find him enjoying alternative rock music, swimming,
          reading, taking a walk or riding his motorcycle in the region of Appelscha, Netherlands
        </p>
        <a href="mailto:patrick@tingen.net">patrick@tingen.net</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'patricktingen' }">
            View sessions by Patrick Tingen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<!-- Cristina Truta -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/cristina_truta.jpg" class="card-img" alt="Cristina Truta" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Cristina Truta</h4>
        <h5 class="card-title">Tech Lead, Wayfare</h5>

        <p class="card-text">
          With over 15 years of experience with OpenEdge, Cristina Truta
          is a lead developer at Wayfare. Working on various teams allowed
          her to gain tremendous expertise in designing and developing
          successful products. She is enthusiastic when it comes to mentoring
          others and sharing knowledge. She is keen to solve the most intricate problems;
          always balancing between performance, maintenance and customer satisfaction.
          When she's not writing code, she is a mother of two.
        </p>
        <a href="mailto:cristina.truta@wayfare.ro">cristina.truta@wayfare.ro</a>
        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'cristinatruta' }">
            View sessions by Cristina Truta
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr>


<!-- Coenie Vermaak -->
<div class="card" style="max-width: 100%">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="../assets/images/speakers/coenie.jpeg" class="card-img" alt="coenie_vermaak" />
    </div>
    <div class="col-md-8">
      <div class="card-body pb-0 pt-md-0 pt-lg-0">
        <h4 class="card-title">Coenie Vermaak</h4>
        <h5 class="card-title">
          Principle Solutions Architect, Dimension Data
        </h5>

        <p class="card-text">
          Coenie has been working in the software design and development
          industry for 20 years, 11 years of which was spent working with the
          OpenEdge product suite, and 9 years working with Microsoft
          technologies.
        </p>
        <p class="card-text">
          Coenie started his career in software development, after completing a
          Bsc Computational Intelligence degree, as a C++ developer passionate
          about Artificial Intelligence and Machine Learning. In 2008 Coenie
          joined Dimension Data (formerly Britehouse Automotive) as a Progress
          OpenEdge developer specializing in the design and development of the
          Dealer Management System, which is the core product of the company.
        </p>
        <p class="card-text">
          In 2012 Coenie furthered his career as Systems Architect and from 2013
          Coenie was the Systems Architect for the Application Modernization
          team, focusing on the following:
        </p>
        <ul>
          <li>
            Due Diligence of the modernization project from a technical
            perspective
          </li>
          <li>
            Design and documentation of the modernized application architecture
          </li>
          <li>
            Implementation of the toolset required by the modernization team
          </li>
          <li>Design and implementation of automated deployment system</li>
        </ul>
        <div class="coenie-collapsible collapse">
          <p class="card-text">
            Coenie has also been involved with the OpenEdge community by
            presenting numerous topics at:
          </p>
          <ul>
            <li>EMEA PUG Challenges</li>
            <li>PUG Challenge Americas</li>
            <li>PUG Challenge South Africa</li>
            <li>Some smaller local conferences in South Africa.</li>
          </ul>
        </div>
        <button id="coenie-more" class="btn btn-link">Show more</button>
        <button id="coenie-less" class="btn btn-link">Show less</button>
        <br />
        <a href="mailto:coenraad.vermaak@dimensiondata.com">coenraad.vermaak@dimensiondata.com</a>

        <div class="mt-3 ml-0">
          <button class="k-button k-button-outline" [routerLink]="'/sessions'"
            [queryParams]="{ speaker: 'coenievermaak' }">
            View sessions by Coenie Vermaak
          </button>
        </div>
      </div>
    </div>
  </div>
</div>