import { SessionsByTrack } from "../../sessions/sessions.service";
import { Session } from "../../model/session.model";
import * as _ from 'lodash';
export function getAvailableTagsFromSessions(sessions: Session[]): string[] {
  const tags: string[] = [];
  for (const tag of sessions
    .map((s) => s.Session_Tags)
    .reduce((prev, curr) => [...prev, ...curr], [])) {
    if (!tags.includes(tag)) {
      tags.push(tag);
    }
  }
  return tags.sort();
}

export function getTagOccurrencesFromSessions(
  sessions: Session[]
): TagOccurrence[] {
  const map: { [tag: string]: number } = {};
  for (const session of sessions) {
    const tags = session.Session_Tags;
    for (const tag of tags) {
      if (typeof map[tag] !== "number") {
        map[tag] = 0;
      }
      map[tag]++;
    }
  }
  return Object.keys(map).map((tag) => ({ tag, occurrences: map[tag] }));
}

export type TagOccurrence = { tag: string; occurrences: number };


export function convertSessionsToSessionsByTrack(sessions: Session[]): SessionsByTrack {
  const tracks = _.uniq(sessions.map((s) => s.Track));
  const grouped: any = {};
  for (const track of tracks) {
    grouped[track] = sessions.filter((s) => s.Track === track);
  }
  return grouped;
}