import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.css']
})
export class NewsletterFormComponent implements OnInit, OnChanges {

  @Input()
  formSource: string;

  @Input()
  formQueryString: string;

  formSourceSafeUrl: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  ngOnChanges() {
    let url = this.formSource;
    if (this.formQueryString) {
      url += `?${this.formQueryString}`;
    }
    this.formSourceSafeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
