import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit, OnDestroy {

  private timerInterval;

  @Input()
  limit: number;

  @Output()
  counterChange: EventEmitter<number> = new EventEmitter();

  @Output()
  timeOver: EventEmitter<void> = new EventEmitter();

  counter: number;

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
  }

  initTimer() {
    this.resetTimer();
    this.timerInterval = setInterval(() => {
      this.counter -= 1000;
      this.counterChange.next(this.counter);
      if (this.counter <= 0) {
        this.stopTimer();
        this.timeOver.next();
      }
    }, 1000);
  }

  resetTimer() {
    this.stopTimer();
    this.counter = this.limit;
    this.counterChange.next(this.counter);
  }

  stopTimer() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  ngOnDestroy() {
    this.stopTimer();
  }

}
