<div id="event" class="parallax">
  <div class="row h-100">
    <div class="col-12 align-self-center title">
      <div class="row">
        <div class="col-12 col-lg-6">
          <h1 class="text-center">Progress OpenEdge Technology Summit</h1>
          <h3 class="text-center">
            November 9<sup>th</sup> and 10<sup>th</sup>, 2021
          </h3>
          <h2 class="text-center">Presentation Recordings on YouTube now!</h2>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" id="info">
  <div class="section">
    <p class="section-text">
      The Progress OpenEdge Technology (POET) Summit is the online conference for everyone interested in Progress
      OpenEdge. The conference will be presenting around 40 sessions led by community experts and is targeting any user
      of OpenEdge technology such as Software Developers, Architects, Database Administrators, DevOps engineers, ...
    </p>
    <p class="section-text">
      Finally - the wait is over! Presentations from the POET Summit conference for Progress OpenEdge users held in
      November 2021 are now publicly available on our YouTube channel at <a target="_blank"
        href="https://bitly.com/poet-videos">https://bitly.com/poet-videos</a>.
    </p>
    <p class="section-text">
      At this time, we’re busy in joint forces with the EMEA PUG Challenge team and are in preparation for the next
      hybrid conference to be held in the early fall 2022. Stay tuned for updates!
    </p>
    <p class="section-text">
      If you have questions, please email us at
      <a href="mailto:poet-summit@consultingwerk.com">poet-summit@consultingwerk.com</a>.
    </p>
  </div>
</div>

<div id="sponsors-parallax" class="parallax"></div>
<div class="container" id="sponsors">
  <div class="section">
    <span class="section-text">
      <h1 class="text-center">Sponsors</h1>
    </span>
    <p class="section-text">
      We thank the following organizations for sponsoring and supporting the
      event. This event would not be possible without their contribution!
    </p>
  </div>
  <div class="section">
    <app-sponsors></app-sponsors>
  </div>
  <hr />
  <div class="section">
    <span class="section-text">
      <h3 class="text-center mb-5 pb-2">Supporters</h3>
    </span>
    <p class="section-text">
      We thank the following organizations for supporting the conference by
      providing the break-out sessions.
    </p>
  </div>
  <div class="section">
    <app-supporters></app-supporters>
  </div>
</div>

<div id="nerd-quiz-parallax" class="parallax"></div>

<div class="container" id="nerd-quiz">
  <div class="section">
    <nerd-quiz-section></nerd-quiz-section>
  </div>
</div>

<div id="speakers-parallax" class="parallax"></div>

<div class="container" id="speakers">
  <div class="section">
    <span class="section-text">
      <h1 class="text-center">Speakers</h1>
    </span>
    <p class="section-text">
      Below is an overview of some of the speakers at the Progress OpenEdge
      Technology Summit 2021. The list of speakers will be added to on an
      ongoing basis.
    </p>
  </div>
  <div class="section">
    <speakers></speakers>
  </div>
</div>

<div id="program-parallax" class="parallax"></div>

<div class="container" id="program">
  <div class="section">
    <span class="section-text">
      <h1 class="text-center">Program</h1>
    </span>
    <p class="section-text">
      The conference will be presenting around 40 break-out sessions led by
      community experts and is targeting any user of OpenEdge technology such as
      Software Developers, Architects, Database Administrators, DevOps
      engineers, ...
    </p>
    <p class="section-text">
      The conference will be live-streaming on two days from Cologne, Germany.
      Sessions will be scheduled to stream from 11:00 CET and run into the early
      evening hours CET to allow delegates in Europe and the Americas to join
      the majority of live sessions at convenient times.
    </p>
    <div class="highlighted-card">
      <p class="section-text">
        <em [class]="'fa fa-star'"></em>
        Registered delegates can add or remove sessions to their <i>favorite sessions</i>
        by clicking on the "star" icon before the session title. A digest of your
        favorite sessions can be found by selecting the "My Sessions" under the
        user profile menu (top right).
      </p>
    </div>
    <div class="section-text text-center">
      <div class="w-100">
        <app-tag-cloud class="mx-auto"></app-tag-cloud>
      </div>
    </div>
    <p class="section-text text-center">
      <button class="k-button k-primary" [routerLink]="'/sessions'">
        Sessions Overview
      </button>
    </p>
  </div>
</div>

<div id="newsletter-parallax" class="parallax"></div>

<div class="container" id="newsletter-form">
  <div class="section">
    <div class="newsletter-form">
      <h2 class="text-center">Register for our newsletter</h2>
      <app-newsletter-form [formSource]="formSource" [formQueryString]="formQueryString"></app-newsletter-form>
    </div>
  </div>
</div>

<div id="registration-parallax" class="parallax" *ngIf="!(isAuthenticated | async)"></div>

<div class="container" id="registration" *ngIf="!(isAuthenticated | async)">
  <div class="section">
    <span class="section-text">
      <h1 class="text-center">Registration</h1>
    </span>
    <p class="section-text">
      Registration to the conference is free of charge!
    </p>
    <p class="section-text">
      As a registered delegate you will be able to select favorite sessions and
      receive reminders when the sessions are about to start.
    </p>
    <p class="section-text">
      If you have questions, please email us at
      <a href="mailto:poet-summit@consultingwerk.com">poet-summit@consultingwerk.com</a>.
    </p>
    <p class="section-text">
      We will most likely be able to allow for a small number of vaccinated
      delegates to join us on-site in Cologne. Attendance in Cologne will be on
      a first-come first-serve base. Please note, that we will only be accepting
      fully vaccinated or recovered delegates on site (following the German 2G
      rule). If you are interested in joining us on-site in Cologne, please
      reach out to us at
      <a href="mailto:poet-summit@consultingwerk.com">poet-summit@consultingwerk.com</a>
      so that we can put you on the waiting list and discuss details with you.
    </p>
    <app-register></app-register>
  </div>
</div>