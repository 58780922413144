import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ISession, Session } from "../model/session.model";
import { map } from "rxjs/operators";

import * as _ from "lodash";
import { convertSessionsToSessionsByTrack } from "../shared/sessions/util";

@Injectable()
export class SessionsService {
  constructor(private http: HttpClient) {}

  getSessionsPasoe(authenticated = false): Observable<Session[]> {
    return this.http.get<Session[]>(`https://poet-summit.org/${authenticated ? 'registered' : 'public'}/web/Entities/Presentations/All`)
              .pipe(
                map(sessions => sessions.map(s => new Session(s)))
              );
  }

  getUserSessions(): Observable<SessionsByTrack> {
    return this.http.get<Session[]>('https://poet-summit.org/registered/web/Entities/Presentations/My')
    .pipe(
      map(sessions => sessions.map(s => new Session(s))),
      map((sessions: Session[]) => convertSessionsToSessionsByTrack(sessions))
    );
  }

  getSessionById(sessionId: string, authenticated = false): Observable<Session> {
    return this.getSessionsPasoe(authenticated).pipe(
      map(sessions => sessions.find(s => s.Session_ID === sessionId))
    );
  }

  getSessions(includeUnconfirmed = false): Observable<Session[]> {
    const params = new HttpParams({
      fromObject: {
        _ts: String(Date.now())
      }
    })
    return this.http.get<ISession[]>("/assets/presentations.json", { params })
                    .pipe(
                      map(sessions => includeUnconfirmed ? sessions : sessions.filter(s => s.Status !== undefined && s.Status.toLowerCase() !== 'no')),
                      map(sessions => sessions.map(session => new Session(session)))
                    );
  }

  getSessionsByTracks(authenticated = false): Observable<SessionsByTrack> {
    return this.getSessionsPasoe(authenticated).pipe(
      map((sessions) => {
        return convertSessionsToSessionsByTrack(sessions);
      })
    );
  }

  markSessionAsFavorite(session: Session, isFavorite = true): Observable<void> {
    let url = `https://poet-summit.org/registered/web/Entities/Sessions/${session.Session_ID}/`;
    url += isFavorite ? 'star' : 'unstar';

    return this.http.get<void>(url);
  }
}

export type SessionsByTrack = { [trackName: string]: Session[] };
