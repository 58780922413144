import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITimeZone, TimeZonesService } from '../timezones.service';

@Component({
  selector: 'app-timezones-dropdown',
  templateUrl: './timezones-dropdown.component.html',
  styleUrls: ['./timezones-dropdown.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimezonesDropdownComponent),
      multi: true
    }
  ]
})
export class TimezonesDropdownComponent implements OnInit, ControlValueAccessor {

  private _onChange: Function;
  private _onTouched: Function;
  
  timezones: ITimeZone[];
  timezonesView: ITimeZone[];
  value: string;
  disabled: boolean;

  constructor(private timezoneService: TimeZonesService) { }

  writeValue(obj: string): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(newValue: string) {
    this.value = newValue;
    if (typeof this._onChange === 'function') {
      this._onChange(newValue);
    }
  }

  onBlur() {
    if (typeof this._onTouched === 'function') {
      this._onTouched();
    }
  }

  ngOnInit() {
    this.timezoneService
      .getTimeZones()
      .subscribe((timezones) => {
        this.timezones = timezones;
        this.timezonesView = timezones;
      });
  }

}
