import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, Input } from '@angular/core';
import { isPhoneSized } from 'mobile-detect';
import { Session } from '../model/session.model';
import { SessionsService } from '../sessions/sessions.service';
import { FeedbackService } from '../shared/feedback.service';
import { UserService } from '../shared/user.service';
import { ISchedule } from './schedule.model';
import { ScheduleService } from './schedule.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  // public tuesday: Date = new Date('2021-11-09');
  // public wednesday: Date = new Date('2021-11-10');
  public selectedDate: number = 0;
  public selectedRoom: number;
  public loading = false;

  public schedule: ISchedule;
  private _authenticated: boolean;
  constructor(
    private scheduleService: ScheduleService, 
    private userService: UserService, 
    private sessionsService: SessionsService, 
    private feedbackService: FeedbackService
  ) { }

  ngOnInit() {
    const today = new Date();
    const isWednesday = today.getDate() === 10 && today.getMonth() === 10 && today.getFullYear() === 2021;
    this.selectedDate = isWednesday ? 1 : 0;
    this.selectedRoom = 1;

    window.addEventListener("orientationchange", this.setIsMobileDevice, false);

    this.userService.authenticated.subscribe((authenticated) => {
      this._authenticated = authenticated;
      this.getSchedule();
    });

    this.getSchedule();
  }

  getSchedule() {
    this.loading = true;
    this.scheduleService.getSchedule(this._authenticated).subscribe((sch) => {
      this.schedule = sch;
      this.loading = false;
    }, err => {
      this.loading = false;
      this.feedbackService.handleError(err);
    })
  }

  setIsMobileDevice = () => {
    this.scheduleService.setIsMobileDevice(window.innerWidth <= 767);
  };

  toggleSessionFavorite(ev: {session: Session, isFavorite: boolean}) {
    this.sessionsService
      .markSessionAsFavorite(ev.session, ev.isFavorite)
      .subscribe(() => (ev.session.Favorite_Session = ev.isFavorite), err => {
        this.feedbackService.handleError(err);
      });
  }

}
