import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable, of, ReplaySubject, throwError } from "rxjs";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";
import { progress } from "@consultingwerk/smartcomponents-jsdo-core";
import { md5 } from "./md5";

@Injectable()
export class UserService {
  readonly signedOut: EventEmitter<void> = new EventEmitter();
  readonly signedIn: EventEmitter<void> = new EventEmitter();
  readonly profileUpdated: EventEmitter<void> = new EventEmitter();
  readonly authenticated: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private http: HttpClient) {
    this.authenticated.next(false);
  }

  signIn(username: string, password: string): Observable<void> {
    const form = new URLSearchParams();
    form.append("j_username", username);
    form.append("j_password", password);
    form.append("submit", "Login");

    const params = new HttpParams({
      fromObject: {
        _ts: (<any>progress.data.Session)._getNextTimeStamp(),
      },
    });
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    return this.signOut().pipe(
      switchMap(() =>
        this.http.post<void>(
          `https://poet-summit.org/registered/static/auth/j_spring_security_check`,
          form.toString(),
          { params, headers }
        )
      ),
      tap(() => this.authenticated.next(true)),
      tap(() => this.signedIn.next())
    );
  }

  signOut(): Observable<void> {
    return this.http
      .get<void>(
        `https://poet-summit.org/registered/static/auth/j_spring_security_logout`
      )
      .pipe(
        tap(() => {
          this.signedOut.next();
          this.authenticated.next(false);
        })
      );
  }

  getUserProfile(): Observable<UserProfile> {
    return this.http
      .get<UserProfile>(
        `https://poet-summit.org/registered/web/Entities/MyUserProfile`
      )
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            return of(null);
          }
          return throwError(err);
        }),
        tap((profile) => {
          if (profile && !(profile instanceof HttpErrorResponse)) {
            this.authenticated.pipe(take(1)).subscribe((isAuthenticated) => {
              if (!isAuthenticated) {
                this.authenticated.next(true);
              }
            });
          }
        })
      );
  }

  updateUserProfile(updateObject: Partial<UserProfile>): Observable<void> {
    return this.http
      .put<void>(
        `https://poet-summit.org/registered/web/Entities/MyUserProfile`,
        updateObject
      )
      .pipe(tap(() => this.profileUpdated.next()));
  }

  updateUserPassword({ currentPassword, newPassword }): Observable<void> {
    return this.http.post<void>(
      `https://poet-summit.org/registered/web/Entities/UpdatePassword`,
      {
        CurrentPassword: currentPassword,
        NewPassword: newPassword,
      }
    );
  }

  getUserGravatarUrl(email: string): Observable<string> {
    const hash = md5(email);
    return this.http
      .head(`https://gravatar.com/avatar/${hash}?d=404`)
      .pipe(map(() => `https://www.gravatar.com/avatar/${hash}`));
  }

  hasTakenQuiz(profile: UserProfile): Observable<boolean> {
    const params = new HttpParams({
      fromObject: {
        email: profile.DelegateEmail
      }
    });
    return this.http.get<{ isUsed: boolean }>('https://poet-summit.org/api/quiz/questions/emailUsed', { params })
              .pipe(
                map(result => result.isUsed)
              );
  }
  
  forgotPassword(email: string): Observable<any> {
    return this.http.post('https://poet-summit.org/public/web/Entities/ForgotPassword', {
      EmailAddress: email
    });
  } 

  resetPassword(newPassword: string, token: string): Observable<any> {
    return this.http.post('https://poet-summit.org/public/web/Entities/ResetPassword', {
      Token: token,
      Password: newPassword
    });
  }
}

export class UserProfile {
  DelegateName: string;
  DelegateCompany: string;
  DelegateFunction: string;
  DelegateCountry: string;
  DelegateTimeZone: string;
  DelegateOpenEdgeReleases: string;
  DelegateInterest: string;
  DelegateRemarks: string;
  DelegateEmail: string;
}
